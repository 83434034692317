import React, { useEffect, useState } from "react";
import { CustomButton } from "components/atoms/CustomButton";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage";
import { toast } from "react-toastify";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import moment from "moment";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../context/AuthContext";
import { ProgressBar, Image as ImageComponent } from "react-bootstrap";
import { pdfjs } from "react-pdf";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import UploadForm from "./UploadForm";
import ImageSelector from "./ImageSelector";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function AddPost() {
  const { currentUser } = useAuth();

  const navigate = useNavigate();

  const storage = getStorage();

  const [currentPage, setCurrentPage] = useState(0);

  const [previewUrl, setPreviewUrl] = useState(null);
  const [selectedPages, setSelectedPages] = useState([]);

  const [pdfPages, setPdfPages] = useState([]);

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [loadingPublish, setLoadingPublish] = useState(false);

  const handleSubmit = (selectedFile) => {
    setLoadingUpload(true);
    const storage = getStorage();
    const storageRef = ref(storage, `/posts/${selectedFile.name}`);

    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setUploadProgress(
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        );
      },
      (error) => {
        console.log(error);
        setUploadProgress(0);
        setLoadingUpload(false);
      },
      () => {
        setUploadProgress(100);
        getDownloadURL(ref(storage, `/posts/${selectedFile.name}`))
          .then(async (url) => {
            await pdfjs.getDocument(url).promise.then(
              (pdf) => {
                const initialPages = Array.from(
                  { length: pdf.numPages },
                  (_, i) => i + 1
                );
                setSelectedPages(initialPages);
                processPages(pdf);
              },
              async (err) => {
                console.error(err);
                setLoadingUpload(false);
                toast.error(
                  "Error publishing file, check if the document doesn't have errors!"
                );
              }
            );
            setPreviewUrl(url);
            setLoadingUpload(false);
          })
          .catch((err) => console.log(err));
      }
    );
  };

  const handlePageSelection = (pageNumber) => {
    const newSelectedPages = [...selectedPages];
    const pageIndex = newSelectedPages.indexOf(pageNumber);

    if (pageIndex === -1) {
      newSelectedPages.push(pageNumber);
    } else {
      newSelectedPages.splice(pageIndex, 1);
    }

    setSelectedPages(newSelectedPages);
  };

  const uploadImages = async (postId, pages) => {
    const promises = [];
    const extDocIds = [];
    try {
      pages.forEach((pageData, index) => {
        const page = index + 1;
        const storageRef = ref(
          storage,
          `/posts/${postId}/${postId}_page-${page}`
        );

        const uploadPromise = uploadString(storageRef, pageData, "data_url", {
          cacheControl: "max-age=7200",
        }).then(() => {
          return getDownloadURL(storageRef);
        });

        const textExtractionPromise = new Promise((resolve, reject) => {
          onSnapshot(
            query(
              collection(db, "extractedText"),
              where("file", "==", `${storageRef}`)
            ),
            (docSnapshot) => {
              const response = docSnapshot.docs[0];
              if (response?.exists()) {
                extDocIds.push(response.id);
                setCurrentPage((prev) => prev + 1);
                resolve({ ...response.data(), page: page });
              } else {
                console.log(
                  `Waiting for text extraction for image ${postId}_page-${page}`
                );
              }
            },
            (error) => {
              console.error(`Error waiting for text extraction:`, error);
            }
          );
        });

        promises.push(Promise.all([uploadPromise, textExtractionPromise]));
      });
    } catch (err) {
      console.log(err);
      setLoadingPublish(false);
      toast.error("Error processing file");
      deleteDoc(doc(db, "posts", postId));
    }

    await Promise.all(promises)
      .then((results) => createPreview(postId, results))
      .catch((err) => {
        setLoadingPublish(false);
        console.log(err);
        toast.error("Error publishing file");
      });
  };

  const createPreview = async (postId, results) => {
    const pages = results.map(([imgURL, extractedText]) => ({
      postId: postId,
      postUserId: currentUser?.uid,
      imageURL: imgURL,
      page:
        extractedText.page < 10
          ? `0${extractedText.page}`
          : extractedText.page.toString(),
      text: extractedText.text,
      notes: "",
      createdAt: moment().toISOString(),
    }));

    setDoc(doc(db, "posts", postId), {
      imageURL: pages.sort((a, b) => parseInt(a.page) - parseInt(b.page))[0]
        .imageURL,
      description: title,
      longDescription: description,
      gallery: "",
      artists: [],
      archived: false,
      userId: currentUser.uid,
      userEmail: currentUser.email,
      userDisplayName: currentUser.displayName,
      phones: [],
      shareRequests: [],
      likes: [],
      buys: [],
      urgents: [],
      comments: [],
      flags: [],
      inventory: [],
      events: [],
      createdAt: moment().toISOString(),
      nPages: pages.length,
    })
      .then(async () => {
        await Promise.all(
          pages.map((page) => {
            setDoc(doc(db, "posts", postId, "pages", page.page), page).catch(
              (err) => console.log(err)
            );
          })
        );
        setLoadingPublish(false);
        toast.success("Preview published successfully");
        navigate(`/post-o-table/${postId}`);
      })
      .catch((err) => {
        setLoadingPublish(false);
        console.log(err);
      });
  };

  const optimizeImageDataURL = (dataURL, maxWidth, maxHeight, quality) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = dataURL;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let { width, height } = img;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              resolve(reader.result);
            };
          },
          "image/jpeg",
          quality
        );
      };

      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  const processPages = (pdf) => {
    const pageProcessing = Array.from(
      { length: pdf.numPages },
      (_, i) => i + 1
    ).map((pageNum) => {
      return pdf.getPage(pageNum).then((page) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const viewport = page.getViewport({ scale: 1.5 });

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        return page.render(renderContext).promise.then(() => {
          const dataURL = canvas.toDataURL("image/jpeg", 1);
          return optimizeImageDataURL(dataURL, 800, 600, 1);
        });
      });
    });

    Promise.all(pageProcessing)
      .then(async (processedPages) => {
        const pages = Array.from({ length: pdf.numPages }, (_, i) => i + 1).map(
          (index) => ({
            page: index,
            imageURL: processedPages[index - 1],
          })
        );
        setPdfPages(pages);
      })
      .catch((err) => {
        console.log(err);
        setLoadingPublish(false);
        toast.error("Error publishing file");
      });
  };

  const publishPreview = async () => {
    setLoadingPublish(true);
    const post = await addDoc(collection(db, "posts"), {});
    await uploadImages(
      post.id,
      pdfPages
        .filter((page) => selectedPages.includes(page.page))
        .map((page) => page.imageURL)
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="d-flex flex-column min-vh-100">
      <div className="mx-0 my-[40px] sm:!mx-[40px] ">
        <UploadForm
          uploadProgress={uploadProgress}
          onSubmit={handleSubmit}
          isReady={!!previewUrl}
          title={title}
          setTitle={setTitle}
          description={description}
          setDescription={setDescription}
        ></UploadForm>
        {previewUrl && !loadingUpload && (
          <>
            <div className="my-[20px] !mx-[20px] sm:!mx-0 flex flex-col sm:flex-row  justify-between">
              <span className="mb-[20px] sm:mb-0">
                Select the pages you want to keep and exclude the others before
                publishing the Capsule.
              </span>
              <div>
                {loadingPublish ? (
                  <div>
                    <span style={{ fontSize: "14px" }}>
                      Publishing your Preview. Please don't close this window.
                    </span>
                    <ProgressBar
                      variant="dark"
                      now={currentPage}
                      max={selectedPages.length}
                    />
                  </div>
                ) : (
                  <CustomButton
                    onClick={publishPreview}
                    className="h-[40px] w-full sm:w-48"
                  >
                    Publish {selectedPages.length} Selected
                  </CustomButton>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[20px]">
              {pdfPages.length > 0 &&
                pdfPages.map((page, i) => {
                  return (
                    <ImageSelector
                      isSelected={selectedPages.includes(i + 1)}
                      onPageSelected={() => handlePageSelection(i + 1)}
                      imageURL={page.imageURL}
                    ></ImageSelector>
                  );
                })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AddPost;
