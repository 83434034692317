import { doc, getDoc, collection, addDoc, setDoc, getDocs, writeBatch } from "firebase/firestore"
import moment from "moment"
import { db } from "../firebaseConfig";

export const sendNotification = async (fromId, toId, type, postId, orderId, pageNumber, commentId) => {
    const toRef = doc(db, "users", toId)
    const toSnap = await getDoc(toRef)
    const toData = toSnap.data()

    const fromRef = doc(db, "users", fromId)
    const fromSnap = await getDoc(fromRef)
    const fromData = fromSnap.data()

    const subCollectionRef = collection(toSnap.ref, "notifications")

    const havePostId = ['sharePost', 'newComment','replyComment']
    const havePageNumber = ['newComment', 'replyComment']
    const haveOrderId = ['orderCreated', 'orderUpdated', 'shareOrder']

    try {
        await addDoc(subCollectionRef, {
            toId: toId,
            toDisplayName: toData?.displayName,
            toEmail: toData?.email,
            fromId: fromId,
            fromDisplayName: fromData?.displayName,
            fromEmail: fromData?.email,
            type: type,
            postId: postId || null,//havePostId.includes(type) ? docId : null,
            pageNumber: pageNumber || null, //havePageNumber.includes(type) ? pageNumber : null,
            orderId: orderId || null, //haveOrderId.includes(type) ? docId : null,
            commentId: commentId || null,
            seen: false,
            createdAt: moment().format("YYYY-MM-DD hh:mm:ss a")
        })
    } catch (error) {
        console.log(error)
    }

    
}

export const makeNotificationSeen = async (user, id) => {
    const docRef = doc(db, "users", user?.uid, "notifications", id)
    const docSnap = await getDoc(docRef)
    const data = docSnap.data()
    if(!data?.seen){
        try {
            await setDoc(docRef, {
                ...data,
                seen: true
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export const markAllAsRead = async (user) => {
    const collectionRef = collection(db, "users", user?.uid, "notifications")
    const notifications = await getDocs(collectionRef)

    const batch = writeBatch(db)

    notifications.docs.forEach(doc => {
        batch.update(doc.ref, {
            seen: true
        })
    })

    await batch.commit()
}