import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";

export const NavTabItem = ({ sub, closeNav }) => {

  const location = useLocation()

  return (
    <NavDropdown.Item as="li" key={sub.title}>
      <NavLink
        to={`${sub.path}`}
        className={`nav-link navbar-text rounded p-2 ms-1 ${
          sub.path === location.pathname &&
          "text-secondary"
        }`}
        onClick={closeNav}
      >
        {sub.title}
      </NavLink>
    </NavDropdown.Item>   
  )
}

function NavTab({ tab, closeNav }) {

  return (
    <NavDropdown
      key={tab.title}
      title={tab.title}
      id={`offcanvasNavbarDropdown-expand-xl`}
      className="py-2 pe-5 ps-3"
      // align="end"
    >
      {tab.subMenu.map((sub) => (
        <NavTabItem sub={sub} closeNav={closeNav} />
      ))}
    </NavDropdown>
  );
}

export default NavTab;
