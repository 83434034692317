import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";


const ToggleItems = ({invite, newUsers, setNewUsers}) => {

  const [radioValue, setRadioValue] = useState('none')

  const radios = [
    {name: 'Seller', value: 'seller'},
    {name: 'Buyer', value: 'buyer'},
  ]

  const handleChange = (e, newAlignment) => {
    setRadioValue(newAlignment)
  }

  useEffect(() => {
    if(radioValue){
      setNewUsers(newUsers.map(nu => nu?.value !== invite?.value ? nu : ({
        ...nu,
        role: nu?.value === invite?.value ? radioValue : null
      })))
    }
  }, [radioValue])

  return (
    <ToggleButtonGroup value={radioValue} exclusive onChange={handleChange}>
      {radios?.map((radio) => (
        <ToggleButton value={radio.value}>
          {radio.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}


const AdminInviteModal = ({ invites, show, setShow, handleInvite, setLoading }) => {

  const handleClose = () => {
    setLoading(false)
    setShow(false)
  }
  const [newUsers, setNewUsers] = useState([])

  useEffect(() => {
    setNewUsers(invites?.filter(user => user?.__isNew__).map(user => ({...user, role: 'none'})))
  }, [invites])

  const handleAdminInvite = () => {
    handleInvite([...invites.filter(user => !user?.__isNew__), ...newUsers.map(user => ({...user, role: user.role === 'none' ? null : user.role}))])
    handleClose()
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Invite New User to Beta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please select if the user you are inviting is a buyer or seller of art. This will automatically bypass the payment page for them and send them a request for them to join your network.</p>
        {newUsers.map((invite) => (
          <div className="d-flex w-full justify-content-between align-items-center mb-5">
            <p className="m-0">{invite?.label}</p>
            <ToggleItems invite={invite} newUsers={newUsers} setNewUsers={setNewUsers}/>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleAdminInvite}>Invite to Beta</Button>
      </Modal.Footer>
    </Modal>
  )
  
  
};

export default AdminInviteModal;
