import React, { useState, useEffect } from "react";
import { useAuth } from '../context/AuthContext'
import DefaultLayout from "../components/DefaultLayout";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import HomePageCarousel from "../components/HomePageCarousel";
import { collection, getDocs, query, where, getDoc, doc, orderBy, limit, setDoc, updateDoc } from "@firebase/firestore";
import { db } from "../firebaseConfig";
import Loader from "../components/Loader";
import moment from "moment/moment";
import { ConnectionListItem } from "../components/ConnectionList";


const Dashboard = () => {

  const navigate = useNavigate()

  const { currentUserRealTime: currentUser, isConnoisseur, isCurious, isVendor } = useAuth()

  const [inboxItems, setInboxItems] = useState([])
  const [outboxItems, setOutboxItems] = useState([])
  const [loadingInboxItems, setLoadingInboxItems] = useState(false)
  const [loadingOutboxItems, setLoadingOutboxItems] = useState(false)

  const [trackerBuyer, setTrackerBuyer] = useState([])
  const [trackerSeller, setTrackerSeller] = useState([])
  const [trackerVendor, setTrackerVendor] = useState([])
  const [loadingTracker, setLoadingTracker] = useState(false)

  const [myCollection, setMyCollection] = useState([])
  const [myNews, setMyNews] = useState([])
  const [myConnections, setMyConnections] = useState([{value: 'Teste'}, {value: 'Teste 2'}, {value: 'Teste 3'}])

  const filterOrders = order => {
    if(isConnoisseur()) return !order.archivedByConnoisseur
    if(isCurious()) return !order.archivedByCurious
    return true
  }

  const getPosts = () => {
    setLoadingOutboxItems(true)

    getDocs(
      query(
        collection(db, 'posts'),
        where('userId', '==', currentUser?.uid),
        where('archived', '==', false),
        orderBy('createdAt', 'desc'),
        limit(3)
    )).then(res => {
      setOutboxItems(
        res.docs
          .map(d => ({...d.data(), id: d.id}))
          .map(post => ({
            title: post.description,
            image: post?.imageURL,
            link: `/post-o-grid/${post.id}`
          }))
      )
    })
    .then(() => setLoadingOutboxItems(false))
    .catch(error => {
      console.log(error)
      setLoadingOutboxItems(false)
    })
  }

  const getSharedPosts = async () => {
    setLoadingInboxItems(true)
    const response = await getDocs(query(collection(db, 'sharedPosts'), where('sharedWith', '==', currentUser?.uid)))
    const shares = response.docs.map(doc => ({...doc.data(), id: doc.id}))

    const sharedPosts = await Promise.all(
      shares.map(async sh => {
        const postDoc = await getDoc(doc(db, 'posts', sh.post))
        const user = await getDoc(doc(db, 'users', sh.sharedBy))
        return {...sh, post: {...postDoc.data(), id: postDoc.id}, sharedBy: user.data()}
      })
    )
    
    setInboxItems(
      sharedPosts
        .sort((a, b) => {
          const momentA = moment(a.createdAt, 'YYYY-MM-DD hh:mm:ss a');
          const momentB = moment(b.createdAt, 'YYYY-MM-DD hh:mm:ss a');
    
          return momentB.utc().valueOf() - momentA.utc().valueOf();
        }).map(sp => ({
          title: sp?.post.description,
          image: sp?.post?.imageURL,
          link: `/post-s-grid/${sp.post.id}`
        }))
    )

    setLoadingInboxItems(false)
  }

  const getSharedOrders = async () => {
    setLoadingTracker(true)
    const response = await getDocs(query(collection(db, 'sharedOrders'), where('sharedWith', '==', currentUser?.uid)))
    const shares = response.docs.map(doc => ({...doc.data(), id: doc.id}))

    const sharedOrders = await Promise.all(
      shares.map(async sh => {
        const orderDoc = await getDoc(doc(db, 'orders', sh.order))
        const user = await getDoc(doc(db, 'users', sh.sharedBy))
        return {...sh, order: {...orderDoc.data(), id: orderDoc.id}, sharedBy: user.data()}
      })
    )
    
    setTrackerVendor(
      sharedOrders
        .sort((a, b) => {
          const momentA = moment(a.createdAt, 'YYYY-MM-DD hh:mm:ss a');
          const momentB = moment(b.createdAt, 'YYYY-MM-DD hh:mm:ss a');
    
          return momentB.utc().valueOf() - momentA.utc().valueOf();
        })
        .map(so => so.order)
        .map(order => ({
          title: order?.postDescription,
          image: order?.imageURL,
          link: `/order-s-status/${order?.id}`
        }))
    )

    setLoadingTracker(false)
  }

  const getTrackers = () => {
    setLoadingTracker(true)
    
    getDocs(collection(db, 'orders'))
      .then(response => {
        const items = []
        
        response.docs.map(doc => {
          items.push({id: doc?.id, ...doc.data()})
        })

        setTrackerSeller(
          items
            ?.filter(item => item?.shareInfo?.sharedById === currentUser?.uid)
            ?.filter(filterOrders)
            ?.sort((a,b) => moment(b.createdOn) - moment(a.createdOn))
            ?.map(order => ({
              title: order?.postDescription,
              image: order?.imageURL,
              link: `/order-o-status/${order?.id}`
            }))
        )

        setTrackerBuyer(
          items
            ?.filter(item => item?.userId === currentUser?.uid)
            ?.filter(filterOrders)
            ?.sort((a,b) => moment(b.createdOn) - moment(a.createdOn))
            ?.map(order => ({
              title: order?.postDescription,
              image: order?.imageURL,
              link: `/order-s-status/${order?.id}`
            }))
        )

      })
      .then(() => setLoadingTracker(false))
      .catch(err => setLoadingTracker(false))
  }

  useEffect(() => {
    if(currentUser){
      getPosts()
      getSharedPosts()
      getSharedOrders()
      getTrackers()
    }
  }, [currentUser])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout>
      <div className="mx-4">
        <Row className="my-3">
          <Col sm={12}>
            <p style={{margin: 0, fontSize: '35px', fontWeight: 'bold'}}>welcome back, @{currentUser?.username}!</p>
          </Col>
        </Row>
        <Row>
          {isConnoisseur() &&
            <Col sm={12} md={12} lg={6}>
              <Row className="mb-3">
                <Col>
                  <span style={{fontSize: '24px'}}><span style={{fontWeight: 'bold'}}>SELLER</span> VIEW</span>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={6} className="mb-5">
                  <Card style={{height: '100%'}}>
                    <Card.Header className="text-center" as='h5' onClick={() => navigate('/profile-grid/')} style={{cursor: 'pointer'}}>OUTGOING PREVIEWS</Card.Header>
                    <Card.Body className="d-flex justify-content-center">
                      {loadingOutboxItems ? <Loader/> : <HomePageCarousel list={outboxItems?.slice(0,3)}/>}
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={12} lg={6} className="mb-5">
                  <Card style={{height: '100%'}}>
                    <Card.Header className="text-center" as='h5' onClick={() => navigate('/acquisition-status-seller/')} style={{cursor: 'pointer'}}>PENDING SALES</Card.Header>
                    <Card.Body className="d-flex justify-content-center">
                      {loadingTracker ? <Loader/> : <HomePageCarousel list={trackerSeller?.slice(0,3)}/>}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={6} className="mb-5">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: 'rgba(33, 37, 41, 0.03)',
                      border: '1px solid rgba(0, 0, 0, 0.175)',
                      borderRadius: '5px',
                      padding: '12px 0',
                      cursor: 'pointer'
                    }}
                    onClick={() => navigate('/addPost')}  
                  >
                    <span>UPLOAD</span>
                    <span style={{fontWeight: 'bold'}}>PREVIEW</span>
                  </div>
                </Col>
                <Col sm={12} md={12} lg={6} className="mb-5">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: 'rgba(33, 37, 41, 0.03)',
                      border: '1px solid rgba(0, 0, 0, 0.175)',
                      borderRadius: '5px',
                      padding: '12px 0',
                      cursor: 'pointer'
                    }}
                    onClick={() => navigate('/connections')}  
                  >
                    <span>INVITE</span>
                    <span style={{fontWeight: 'bold'}}>USERS</span>
                  </div>
                </Col>
              </Row>
            </Col>
          }
                    {!isVendor() &&
            <Col sm={12} md={12} lg={!isConnoisseur() ? 8 : 6}>
              <Row className="mb-3">
                <Col>
                  <span style={{fontSize: '24px'}}><span style={{fontWeight: 'bold'}}>BUYER</span> VIEW</span>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={6} className="mb-5">
                  <Card style={{height: '100%'}}>
                    <Card.Header className="text-center" as='h5' onClick={() => navigate('/shares/')} style={{cursor: 'pointer'}}>INCOMING PREVIEWS</Card.Header>
                    <Card.Body className="d-flex justify-content-center">
                      {loadingInboxItems ? <Loader/> : <HomePageCarousel list={inboxItems?.slice(0,3)}/>}
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={12} lg={6} className="mb-5">
                  <Card style={{height: '100%'}}>
                    <Card.Header className="text-center" as='h5' onClick={() => navigate('/acquisition-status-buyer/')} style={{cursor: 'pointer'}}>PENDING PURCHASES</Card.Header>
                    <Card.Body className="d-flex justify-content-center">
                      {loadingTracker ? <Loader/> : <HomePageCarousel list={isVendor() ? trackerVendor?.slice(0,3) : trackerBuyer?.slice(0,3)}/>}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={6} className="mb-5">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: 'rgba(33, 37, 41, 0.03)',
                      border: '1px solid rgba(0, 0, 0, 0.175)',
                      borderRadius: '5px',
                      padding: '12px 0',
                      cursor: 'pointer'
                    }}
                    onClick={() => navigate('/my-feedback')}  
                  >
                    <span>MY</span>
                    <span style={{fontWeight: 'bold'}}>FEEDBACK</span>
                  </div>
                </Col>
                <Col sm={12} md={12} lg={6} className="mb-5">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: 'rgba(33, 37, 41, 0.03)',
                      border: '1px solid rgba(0, 0, 0, 0.175)',
                      borderRadius: '5px',
                      padding: '12px 0',
                      cursor: 'pointer'
                    }}
                    onClick={() => navigate('/collection-client')}  
                  >
                    <span>MY</span>
                    <span style={{fontWeight: 'bold'}}>COLLECTION</span>
                  </div>
                </Col>
              </Row>
            </Col>
          }
          {isVendor() &&
            <Col sm={12} md={12} lg={8}>
              <Row className="mb-3">
                <Col>
                  <span style={{fontSize: '24px'}}><span style={{fontWeight: 'bold'}}>VENDOR</span> VIEW</span>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={12} className="mb-5">
                  <Card style={{height: '100%'}}>
                    <Card.Header className="text-center" as='h5' onClick={() => navigate('/acquisition-status-buyer/')} style={{cursor: 'pointer'}}>Inquiries I've Received</Card.Header>
                    <Card.Body className="d-flex justify-content-center">
                      {loadingTracker ? <Loader/> : <HomePageCarousel list={isVendor() ? trackerVendor?.slice(0,3) : trackerBuyer?.slice(0,3)}/>}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          }       
        </Row>
        {/*<Row>
          <Col sm={12} md={12} lg={!isConnoisseur() ? 8 : 6} className="mb-5">
            <Card>
              <Card.Header className="text-center" as='h5' onClick={() => navigate('/connections/')} style={{cursor: 'pointer'}}>PENDING CONNECTIONS</Card.Header>
              <Card.Body className={`${myConnections.filter(conn => !conn.invitedByMe).length === 0 && 'd-flex'} justify-content-center`}>
                {myConnections.length === 0 ? <p className="m-0">No Pending Invites</p> :
                  <div>
                    {myConnections?.slice(0,3).map(conn => (
                      <ConnectionListItem key={conn.mapKey} item={conn} type={isVendor() ? 'vendors' : 'network'}/>
                    ))}
                  </div>
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>*/}
      </div>
    </DefaultLayout>
  );
};

export default Dashboard;
