import React from "react";

/**
 * @param {object} props
 * @param {React.ReactElement | string | string[]} [props.children]
 * @param {boolean}[props.isDisabled]
 * @param {string} [props.className]
 * @param {"primary"|"secondary"} [props.variant]
 * @param {function():void} [props.onClick]
 */
export const CustomButton = ({
  children,
  onClick,
  isDisabled = false,
  className = "",
  variant = "primary",
}) => {
  return (
    <button
      disabled={isDisabled}
      className={`${
        variant === "primary"
          ? "bg-main text-white border-[1px] border-main"
          : "bg-white text-main border-[1px] border-aux-gray"
      }  px-4 font-medium rounded-none ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
