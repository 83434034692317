import { doc, getDoc } from "firebase/firestore";
import { MDBBadge } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { AiFillDollarCircle, AiFillHeart } from "react-icons/ai";
import { FaComment, FaHandPaper } from "react-icons/fa";
import { db } from "../firebaseConfig";
import UserProfileModal from "./UserProfileModal";

function InputFeedback({ post, page, type, userSelected }) {

  const [showUserProfileModal, setShowUserProfileModal] = useState(false)

  const [userProfile, setUserProfile] = useState()

  const getUserProfile = async (id) => {
    getDoc(doc(db, "users", id, "public", "profile")).then(response => {
      setUserProfile(response.data())
    }).then(() => {
      setShowUserProfileModal(true)
    }).catch(err => console.log(err))
  }

  const filterArray = (array) => {
    const check = (obj) => obj.page === parseInt(page)
    const checkWithUser = (obj) => obj.page === parseInt(page) && obj.id === userSelected.id

    return array
      ?.filter(userSelected.id !== 'all' ? checkWithUser : check)
      ?.sort((a,b) => a.displayName.localeCompare(b.displayName)) || []
  }

  const getIcon = (type) => {
    switch (type) {
      case 'likes':
        return (
          <AiFillHeart
          size={28}
          color={
            filterArray(post[type]).length > 0
              ? "red"
              : "lightGray"
          }
        />
        )
        case 'buys':
          return (
            <AiFillDollarCircle
              size={28}
              color={
                filterArray(post[type]).length > 0
                  ? "blue"
                  : "lightGray"
              }
            />
          )
        case 'comments':
          return (
            <FaComment
              size={28}
              color={
                filterArray(post[type]).length > 0
                  ? "pink"
                  : "lightGray"
              }
            />
          )
        case 'onHold':
          return (
            <FaHandPaper
              size={28}
              color={
                filterArray(post[type]).length > 0
                  ? "#FFAB01"
                  : "lightGray"
              }
            />
          )
    }
  }

  let uniqueUsers = {}

  return (
    <div className="align-items-center d-flex">
      {showUserProfileModal &&
        <UserProfileModal
          profile={userProfile}
          show={showUserProfileModal}
          setShow={setShowUserProfileModal}
        />
      }
      <div className="p-2" style={{marginRight: '20px'}}>
        {getIcon(type)}
        <MDBBadge
          pill
          color="white"
          className="position-absolute p-1 px-2 mx-2 translate-middle-x text-dark"
          style={{
            border:
              "1px solid #C0C0C0",
          }}
        >
          {filterArray(post[type]).length}
          <span className="visually-hidden">
            New alerts
          </span>
        </MDBBadge>
      </div>
      {filterArray(post[type])
        .filter(obj => {
          if(uniqueUsers[obj.id]) return false
          else{
            uniqueUsers[obj.id] = true
            return true
          }
        })
        .map(obj => (
          <MDBBadge
            pill
            color="white"
            style={{
              border:
                "1px solid #C0C0C0",
              color: 'black',
              marginRight: '3px',
              cursor: 'pointer'
            }}
            onClick={() => getUserProfile(obj.id)}
          >
            {obj.displayName}
          </MDBBadge>
        ))
      }
    </div>
  );
}

export default InputFeedback;
