// @ts-check

/**
 * @typedef {import("../utils/types").RouteButton} RouteButton
 * @typedef {import("../utils/types").Capsule} Capsule
 * @typedef {import("../utils/types").Order} Order
 * @typedef {import("../utils/types").DashboardTableData} DashboardTableData
 */

import React, { useEffect, useState } from "react";
import { DashboardTable } from "../components/organisms/DashboardTable";
import { DashboardCard } from "../components/atoms/DashboardCard";
import { CustomButton } from "../components/atoms/CustomButton";
import { CapsuleCarousel } from "../components/organisms/CapsuleCarousel";
import { FeedbackOverview } from "../components/organisms/FeedbackOverview";

import { useAuth } from "../context/AuthContext";
import { collection, getDocs } from "@firebase/firestore";
import { db } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";

const exampleHeaders = ["Header 1", "Header 2", "Header 3", "Header 4"];

const exampleData = [
  {
    artImageUrl: "https://via.placeholder.com/150",
    title: "Laura Lancaster - Small Works",
    actor: "Actor Name",
    status: "Acquisition Inquiry Pending",
  },
  {
    artImageUrl: "https://via.placeholder.com/150",
    title: "Art Title 2",
    actor: "Actor Name 2",
    status: "Acquisition Inquiry Pending",
  },
  {
    artImageUrl: "https://via.placeholder.com/150",
    title: "Art Title 3",
    actor: "Actor Name 3",
    status:
      "The work is on hold for you. Checking with Francois to see if we can extend 15% discount",
  },
];

const exampleEmptyData = [];

/**
 * @type {RouteButton[]}
 */
const exampleRouteButtons = [
  {
    text: "Upload Capsule",
    path: "/addPost",
    variant: "secondary",
  },
  {
    text: "Invite Users",
    path: "/connections",
    variant: "secondary",
  },
];

/**
 * @type {Capsule[]}
 */
const exampleCarouselCapsules = [
  {
    id: "JtK28tkc0uBNTtlxZ8AK",
    created_at: "2021-10-01T00:00:00.000Z",
    description:
      "Sean Kelly - Kehinde Wiley_Marcela Santos de Melo Study, 2008",
    nPages: 5,
    userEmail: "mail",
    userId: "1",
    imageURL: "https://placekitten.com/200/300",
  },
  {
    id: "JtK28tkc0uBNTtlxZ8AK",
    created_at: "2021-10-01T00:00:00.000Z",
    description:
      "Art Title 4 - This is a Long Title, So Long That It Might Overflow, But It's Okay. Some Titles Are Just Long.",
    nPages: 5,
    userEmail: "mail",
    userId: "1",
    imageURL: "https://placekitten.com/200/300",
  },
  {
    id: "JtK28tkc0uBNTtlxZ8AK",
    created_at: "2021-10-01T00:00:00.000Z",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut nisi leo. In sit amet lectus quis ante aliquam rutrum. Curabitur porttitor nisi id semper tincidunt. Fusce rhoncus nibh sapien, ut faucibus est finibus ut. Vestibulum non magna at velit gravida blandit. Nullam scelerisque vulputate diam, nec molestie nulla molestie vel. Integer rutrum cursus vestibulum. Proin at eros iaculis, egestas ipsum ut, auctor erat. Vivamus et neque mi. Morbi commodo ornare tincidunt. Integer massa urna, congue ut pharetra eu, porttitor vitae ligula. Suspendisse pretium tristique lectus, et dapibus erat lobortis in. Aenean tortor tellus, tempor non consequat nec, porta vel libero. Sed ultrices nunc augue, vitae iaculis diam ultricies eget. Nunc ac pellentesque elit, eget tincidunt neque.",
    nPages: 5,
    userEmail: "mail",
    userId: "1",
    imageURL: "https://placekitten.com/300/300",
  },
  {
    id: "JtK28tkc0uBNTtlxZ8AK",
    created_at: "2021-10-01T00:00:00.000Z",
    description: "This is a description",
    nPages: 5,
    userEmail: "mail",
    userId: "1",
    imageURL: "https://placekitten.com/1000/1000",
  },
];

const DashboardNew = () => {
  const {
    currentUserRealTime: currentUser,
    isConnoisseur,
    isCurious,
  } = useAuth();

  const navigate = useNavigate();

  /** @type {DashboardTableData[]} */
  const pendingSalesInitialState = [];

  /** @type {[DashboardTableData[], function(DashboardTableData[]): void]} */
  const [pendingSales, setPendingSales] = useState(pendingSalesInitialState);

  /**
   *
   * @param {Order} order
   * @returns boolean
   */
  const filterOrders = (order) => {
    if (isConnoisseur()) return !order?.archivedByConnoisseur;
    if (isCurious()) return !order?.archivedByCurious;
    return true;
  };

  useEffect(() => {
    if (currentUser) {
      getOrders();
    }
  }, [currentUser]);

  const getOrders = () => {
    getDocs(collection(db, "orders")).then((response) => {
      /**
       * @type {Order[]}
       */
      // @ts-ignore
      const orders = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
        };
      });

      /**
       * @type {DashboardTableData[]}
       */
      const pendingSales = orders
        .filter((item) => item?.shareInfo?.sharedById === currentUser?.uid)
        ?.filter(filterOrders)
        ?.sort((a, b) => {
          const dateA = new Date(a.createdOn).getTime();
          const dateB = new Date(b.createdOn).getTime();
          return dateB - dateA;
        })
        .map((order) => ({
          artImageUrl: order?.imageURL,
          title: order?.postDescription,
          actor: order?.userDisplayName,
          status: order?.status,
          path: `/post-o-grid/${order.id}`,
        }));

      setPendingSales(pendingSales.slice(0, 3));
    });
  };

  return (
    <div>
      <div className="flex flex-col sm:flex-row px-4 mt-4">
        <h1 className="text-center w-full sm:w-auto sm:!text-left font-secondary mr-auto normal text-xl py-1">
          Welcome back, Rachel!
        </h1>
        <div className="mt-auto grid grid-cols-2 gap-1 sm:gap-4  sm:p-0 sm:grid-cols-2">
          <CustomButton variant="secondary" className="w-full h-10  text-base">
            <a href="/connections">Invite user</a>
          </CustomButton>
          <CustomButton className="w-full h-10 text-base">
            <a href="/addPost">Upload Capsule</a>
          </CustomButton>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 gap-4 p-0 md:!p-6 mt-4 md:!mt-0">
        <DashboardCard
          title={"Outgoing Capsules"}
          description={
            "What is a capsule? It’s a preview of an art exhibition, a singular artwork offering, and more."
          }
          callToActionText={"See All"}
          type={"primary"}
        >
          <div className="grid grid-cols-9">
            <div className="col-span-9 md:col-span-4">
              <CapsuleCarousel capsuleList={exampleCarouselCapsules} />
            </div>
            <div className="col-span-9 md:col-span-5 px-3 md:flex md:align-middle md:h-80 ">
              <FeedbackOverview />
            </div>
          </div>
        </DashboardCard>
        <DashboardCard
          title={"Pending Sales"}
          description={
            "These are the Capsules you uploaded that have a buyer interested! Keep in touch with them to finish the sale process."
          }
          callToActionText={"See All"}
          onCallToAction={() => navigate("/acquisition-status-seller/")}
          type={"primary"}
        >
          <DashboardTable
            headers={["Art", "Title", "Buyer", "Status"]}
            data={pendingSales}
            className="mt-4"
          />
        </DashboardCard>

        <DashboardCard
          title={"Ingoing Capsules"}
          description={
            "What is a capsule? It’s a preview of an art exhibition, a singular artwork offering, and more."
          }
          callToActionText={"See All"}
          type={"secondary"}
        >
          <DashboardTable
            variant={"secondary"}
            headers={exampleHeaders}
            data={exampleData}
            className="mt-4"
          />
        </DashboardCard>

        <DashboardCard title={"Ingoing Capsules"} type={"secondary"}>
          {exampleEmptyData.length > 0 ? (
            <DashboardTable
              variant={"secondary"}
              headers={exampleHeaders}
              data={exampleEmptyData}
              className="mt-4"
            />
          ) : (
            <EmptyDashboardCardContent
              text={
                "You don’t have any sales in progress yet. Upload more Capsules and share it with your contacts and your network to start moving on!"
              }
              routeButtons={exampleRouteButtons}
              variant={"secondary"}
            />
          )}
        </DashboardCard>
      </div>
    </div>
  );
};

/**
 * @param {object} props
 * @param {string} props.text
 * @param {RouteButton[]} props.routeButtons
 * @param {"primary"|"secondary"} props.variant
 */
const EmptyDashboardCardContent = ({
  text,
  routeButtons,
  variant = "primary",
}) => {
  return (
    <div
      className={`text-base flex flex-col items-center justify-center h-full p-8 md:p-28  ${
        variant === "primary" ? "text-white " : "text-main"
      }`}
    >
      <p className="text-center font-light">{text}</p>
      <div className="flex flex-col mt-4 w-full">
        {routeButtons.map((button) => (
          <CustomButton
            key={button.text}
            className={`w-full h-10 mb-3 text-main ${
              variant === "primary" ? "!bg-white " : "!bg-transparent"
            }`}
            variant={button.variant ?? "primary"}
          >
            <a href={button.path}>{button.text}</a>
          </CustomButton>
        ))}
      </div>
    </div>
  );
};

export default DashboardNew;
