// @ts-check

import React, { useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import { Carousel, Image } from "react-bootstrap";
import { useNavigate } from "react-router";
import { truncateText } from "../../utils/text";

/**
 * @typedef {import("../../utils/types").Capsule} Capsule
 */
/**
 * @param {{ capsuleList: Capsule[] }} props
 */
export const CapsuleCarousel = ({ capsuleList }) => {
  const navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState(0);

  /**
   * Sets the new index for the carousel in a cyclic manner (after the last element, it goes back to the first)
   * @param {string} direction prev or next
   */
  const setNewIndex = (direction) => {
    if (direction === "prev") {
      if (currentIndex === 0) setCurrentIndex(capsuleList.length - 1);
      else setCurrentIndex((prev) => prev - 1);
    } else {
      if (currentIndex === capsuleList.length - 1) setCurrentIndex(0);
      else setCurrentIndex((prev) => prev + 1);
    }
  };

  /** Swipe on mobile doesn't automatically trigger index change,
   * so we need to handle it manually to avoid a disparity between the index and the displayed item
   */

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) setNewIndex("next");
    if (isRightSwipe) setNewIndex("prev");
  };

  return (
    <div className="flex justify-center w-full px-10 md:!px-0">
      <div className="flex align-items-center">
        <HiChevronLeft
          onClick={() => setNewIndex("prev")}
          className={`cursor-pointer`}
          size={15}
        />
      </div>
      <Carousel
        activeIndex={currentIndex}
        indicators={false}
        controls={false}
        className="w-full rounded-none "
        touch={true}
        data-touch={true}
        data-bs-ride="carousel" // Required for swipe to work
        interval={100000} // Required to stop the carousel from auto-sliding (which is automatically activated by data-bs-ride)
        data-bs-interval={100000} // Idem
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {capsuleList.map((capsule) => (
          <Carousel.Item className="card text-center">
            <div>
              {!capsule.imageURL ? (
                <div className="flex align-middle justify-center w-full">
                  Failed to load preview pages
                </div>
              ) : (
                <Image
                  fluid
                  className="h-80 w-full object-cover cursor-pointer"
                  src={capsule?.imageURL}
                  onClick={() => navigate("/post-o-grid/" + capsule.id)}
                />
              )}
            </div>
            <div className="text-sm text-white font-[100] mt-2 h-10 overflow-auto">
              {truncateText(capsule.description, 80)}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>

      <div className="flex align-items-center">
        <HiChevronRight
          onClick={() => setNewIndex("next")}
          className={`cursor-pointer`}
          size={15}
        />
      </div>
    </div>
  );
};
