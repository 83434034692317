import React from "react";
import { truncateText } from "../../utils/text";

/**
 * @typedef {import("./types").DashboardTableDataProps} DashboardTableDataProps
 */
/**
 * @param {object} props
 * @param {string[]} props.headers
 * @param {Array<DashboardTableDataProps>} props.data
 * @param {string} [props.variant]
 * @param {string} [props.className]
 */
export const DashboardTable = ({
  headers,
  data,
  variant = "primary",
  className = "",
}) => {
  return (
    <div
      className={`${
        variant === "primary" ? "text-white" : "text-main"
      }  grid grid-cols-1 md:grid-cols-4 px-0 font-light ${className}`}
    >
      {headers.map((header, index) => (
        <div
          key={index}
          className="col-span-1  justify-center hidden text-base md:flex"
        >
          {header}
        </div>
      ))}

      {data.map((item, index) => (
        <div
          key={index}
          className={`col-span-1 py-1 px-0 md:gap-2 md:col-span-4 grid grid-cols-1  md:grid-cols-4 my-1.5 rounded-md ${
            variant === "primary" ? "bg-main-ligther" : "bg-aux-gray-ligther"
          }`}
        >
          <div className="col-span-1 my-2 flex justify-center items-center">
            <img
              className="w-24 h-14 rounded-md"
              src={item.artImageUrl}
              alt={item.title}
            />
          </div>
          <DataText text={item.title} />
          <DataText text={item.actor} />
          <DataText text={item.status} />
        </div>
      ))}
    </div>
  );
};

const DataText = ({ text }) => {
  return (
    <p className="col-span-1 my-2 max-h-28 flex justify-center text-center items-center px-6 md:px-0 overflow-hidden">
      {truncateText(text, 90)}
    </p>
  );
};
