/**
 * Function to truncate the text at the last word before the length is exceeded.
 * @param {string} text
 * @param {number} length
 * @returns {string} truncated text with ellipsis if needed.
 */
export const truncateText = (text, length) => {
  if (text.length <= length) return text;
  let truncated = text.substring(0, length);
  let lastSpace = truncated.lastIndexOf(" ");
  if (lastSpace === -1) return truncated + "...";
  return truncated.substring(0, lastSpace) + "...";
};
