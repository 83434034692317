import React, { useEffect, useState } from "react";
import DefaultLayout from "../components/DefaultLayout";
import { Card, Col, Row } from "react-bootstrap";
import ConnectionList from "../components/ConnectionList";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useAuth } from "../context/AuthContext";

const Connections = () => {

  const { currentUserRealTime: currentUser, isConnoisseur, isCurious, isVendor } = useAuth()

  const [network, setNetwork] = useState([])
  const [team, setTeam] = useState([])
  const [vendors, setVendors] = useState([])
  const [nonUsers, setNonUsers] = useState([])

  const [teamOptions, setTeamOptions] = useState([])
  const [networkOptions, setNetworkOptions] = useState([])
  const [vendorsOptions, setVendorsOptions] = useState([])

  const getUsernames = (type, setOptions) => {
    if(currentUser){
      getDocs(collection(db, 'users', currentUser?.uid, type)).then(response => {
        const usedIds = response.docs.map(doc => doc.id)
        const documents = query(collection(db, 'users'), where('role', type === 'vendors' ? '==' : '!=', 'vendor'))
        getDocs(documents).then(response => {
          setOptions(
            response.docs
              .filter(doc => ![...usedIds, currentUser?.uid].includes(doc.id))
              .filter(doc => doc.data().hasOwnProperty("role"))
              .map(doc => ({label: `@${doc.data().username}`, value: doc.id}))
          )
        })
      })
    }
  }

  const getNetwork = () => {
    getDocs(collection(db, 'users', currentUser?.uid, 'network')).then(response => {
      setNetwork(
        response.docs
          .map(doc => ({...doc.data(), id: doc.id}))
          .sort(sortObjectByName)
      )
    }).catch(err => console.log(err))
  }

  const getVendors = () => {
    getDocs(collection(db, 'users', currentUser?.uid, 'vendors')).then(response => {
      setVendors(
        response.docs
          .map(doc => ({...doc.data(), id: doc.id}))
          .sort(sortObjectByName)
      )
    }).catch(err => console.log(err))
  }

  const getNotMembersInvited = () => {
    getDocs(collection(db, 'users', currentUser?.uid, 'notMembersInvited')).then(response => {
      setNonUsers(
        response.docs
          .map(doc => ({...doc.data(), name: doc.id}))
          .sort(sortObjectByName)
      )
    }).catch(err => console.log(err))
  }

  const sortObjectByName = (a, b) => {
    if(a.displayName < b.displayName) return -1
    else if (a.displayName > b.displayName) return 1
    else return 0
  }

  const getLists = () => {
    getNetwork()
    getNotMembersInvited()
    getVendors()
    getUsernames('network', setNetworkOptions)
    getUsernames('vendors', setVendorsOptions)
  }

  useEffect(() => {
    if(currentUser){
      getLists()
    }
  }, [currentUser])

  return (
    <DefaultLayout>
        <Card>
          <Card.Header as="h5" className="d-flex justify-content-center">My Connections</Card.Header>
          <Card.Body className="d-flex justify-content-center w-full flex-column">
            <Card.Title as="h6" className="text-muted">
              <span>
                Search for romulus.art users by their username in the search bar. 
              </span><br/>
              <span>
                If you want to invite a user, type in their email address and click “invite”.
              </span>
            </Card.Title>
            <div>
              <Row style={{width: '100%', justifyContent: 'center'}}>
                {/*isConnoisseur() && <Col sm={12} md={12} lg={4} className="d-flex flex-column pe-4">
                <p style={{fontWeight: 'bold', fontSize: '18px'}}>Team</p>
                  <ConnectionList items={[...team, ...nonUsers?.filter(user => user?.type === 'team')]} options={teamOptions} type="team"/>
                </Col>*/}
                {(isConnoisseur() || isCurious()) && <Col sm={12} md={12} lg={7} className="d-flex flex-column px-3 mb-5">
                  <span style={{fontWeight: 'bold', fontSize: '18px'}}>Network</span>
                  <ConnectionList items={[...network, ...nonUsers?.filter(user => user?.type === 'network').map(user => ({...user, nonUser: true}))]} options={networkOptions} type="network" refreshList={getLists}/>
                </Col>}
                {isConnoisseur() && <Col sm={12} md={12} lg={5} className="d-flex flex-column px-3 mb-5">
                  <span style={{fontWeight: 'bold', fontSize: '18px'}}>Vendors</span>
                  <ConnectionList items={[...vendors, ...nonUsers?.filter(user => user?.type === 'vendors').map(user => ({...user, nonUser: true}))]} options={vendorsOptions} type="vendors" refreshList={getLists}/>
                </Col>}
                {isVendor() && <Col sm={12} md={12} lg={6} className="d-flex flex-column px-3 mb-5">
                  <span style={{fontWeight: 'bold', fontSize: '18px'}}>Sellers</span>
                  <ConnectionList items={[...vendors, ...nonUsers?.filter(user => user?.type === 'vendors').map(user => ({...user, nonUser: true}))]} options={networkOptions} type="vendors" refreshList={getLists}/>
                </Col>}
              </Row>
            </div>
            
          </Card.Body>
        </Card>
    </DefaultLayout>
  );
};

export default Connections;
