// Author: Ciaran O'Keeffe
// Copyright © 2023 Ciaran O'Keeffe All Rights Reserved

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import DefaultLayout from "../components/DefaultLayout";
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { MDBBadge } from "mdb-react-ui-kit";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
// import { UserContext } from "../context";
//import axios from "axios";
// import { BiCommentAdd } from "react-icons/bi";
import { FaComment, FaHandPaper } from "react-icons/fa";
import {
  AiFillHeart,
  AiFillDollarCircle,
  AiOutlineShareAlt,
  AiFillFilter,
  AiFillPhone,
} from "react-icons/ai";
import { IoCaretBackSharp } from "react-icons/io5";
import { FaThList, FaTable, FaShareAlt } from "react-icons/fa";
import { BsFillGrid3X3GapFill } from "react-icons/bs";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { v4 as uuidv4 } from "uuid";
import Sharepost from "./Sharepost";
import SharedWithPopover from "../components/SharedWithPopover";
import { useAuth } from "../context/AuthContext";
import moment from "moment";
import OutgoingPreviewModal from "../components/OutgoingPreviewModal";
// import { v5 as uuidv5 } from "uuid";

function PostDescOgrid() {
  // console.log(
  //   "Code author: Ciaran O'Keeffe, Copyright © 2023 Ciaran OKeeffe All Rights Reserved"
  // );

  const [post, setPost] = useState(null)
  const [pages, setPages] = useState([])
  const allUsers = { displayName: "all", default: true };
  const allUsers2 = { displayName: "all", default: false };
  // const [state, setState] = useContext(UserContext);

  const [showModal, setShowModal] = useState(null)

  const [gridToggled, setGridToggled] = useState(false);

  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [userData, setUserData] = useState([]);
  const [usersSharedWith, setUsersSharedWith] = useState([]);
  const [alreadyPhoned, setAlreadyPhoned] = useState(false);
  const [alreadyShareRequested, setAlreadyShareRequested] = useState(false);
  const [alreadyOnHold, setAlreadyOnHold] = useState(false);

  const [userSelect, setUserSelect] = useState(allUsers);
  const [userSelectId, setUserSelectId] = useState("");

  // const [userClicked, setUserClicked] = useState(false);
  const [userSet, setUserSet] = useState(false);

  //const [alreadyLiked, setAlreadyLiked] = useState(false);

  const [userID, setUserID] = useState();
  const auth = getAuth();

  const { currentUser } = useAuth()

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      setUserID(uid);
      //console.log("user.uid: ", uid);
      //console.log("auth.currentuser: ", auth.currentUser);
      // ...
    } else {
      // User is signed out
      // ...
      // logout();
    }
  });

  const [scrollY, setScrollY] = useState(0)

  const scrollRef = useRef(null)

  const handleScroll = () => {
    setScrollY(scrollRef.current.scrollTop)
  }

  const getData = () => {
    dispatch({ type: "showLoading" });
    getDoc(doc(db, "posts", params.id))
      .then(async (response) => {
        setPost({ ...response.data(), id: response.id });
        await getDocs(query(collection(db, 'posts', response.id, 'pages'), orderBy('page', 'asc'))).then(res => {
          setPages(res.docs.map(docu => ({...docu.data(), id: docu.id})))
        })
        dispatch({ type: "hideLoading" });
      })
      .catch(() => {
        dispatch({ type: "hideLoading" });
      });
  };

  const checkRequests = () => {
    if (post.shareRequests?.length > 0) {
      setAlreadyShareRequested(true);
    }

    if (post.phones?.length > 0) {
      setAlreadyPhoned(true);
    }
  };

  const buyCount = (page) => {
    function checkBuy(buy) {
      return buy.page === parseInt(page);
    }
    const filteredBuys = post.buys?.filter(checkBuy);
    // if (filteredBuys.length > 0) {
    //   console.log("buys?.length > 0 = true");
    // }
    return filteredBuys.length;
  };

  const userBuyCount = (page, user) => {
    function checkBuy(buy) {
      return buy.page === parseInt(page) && buy.id === user.id;
    }
    const filteredBuys = post.buys?.filter(checkBuy);
    return filteredBuys.length;
  };

  const urgentCount = (page) => {
    function checkUrgent(urgent) {
      return urgent.page === parseInt(page);
    }
    const filteredUrgents = post.urgents.filter(checkUrgent);
    return filteredUrgents.length;
  };

  const userUrgentCount = (page, user) => {
    function checkUrgent(urgent) {
      return urgent.page === parseInt(page) && urgent.id === user.id;
    }
    const filteredUrgents = post.urgents.filter(checkUrgent);
    return filteredUrgents.length;
  };

  const likeCount = (page) => {
    function checkLike(like) {
      return like.page === parseInt(page);
    }
    const filteredLikes = post.likes?.filter(checkLike);
    // if (filteredLikes.length > 0) {
    //   console.log("likes?.length > 0 = true");
    //   if (!alreadyLiked) setAlreadyLiked(true);
    // }
    return filteredLikes.length;
  };

  const userLikeCount = (page, user) => {
    function checkLike(like) {
      return like.page === parseInt(page) && like.id === user.id;
    }
    const filteredLikes = post.likes?.filter(checkLike);
    return filteredLikes.length;
  };

  const flagCount = (page) => {
    function checkFlag(flag) {
      return flag.page === parseInt(page);
    }
    const filteredFlags = post.flags.filter(checkFlag);
    return filteredFlags.length;
  };

  const commentCount = (page) => {
    function checkComment(comment) {
      return comment.page === parseInt(page) && comment.parentId === null;
    }
    const filteredComments = post.comments?.filter(checkComment);
    return filteredComments.length;
  };

  const userCommentCount = (page, user) => {
    function checkComment(comment) {
      return comment.page === parseInt(page) && comment.id === user.id  && comment.parentId === null;
    }
    const filteredComments = post.comments?.filter(checkComment);
    return filteredComments.length;
  };

  const onHoldCount = (page) => {
    const checkOnHold = (hold) => hold.page === parseInt(page)
    return post?.onHold?.filter(checkOnHold).length || 0
  }

  const userOnHoldCount = (page, user) => {
    const checkOnHold = (hold) => hold.page === parseInt(page) && hold.id === user.id
    return post?.onHold?.filter(checkOnHold).length || 0
  }

  const goToProfileGrid = () => {
    navigate(`/profile-grid/`);
  };

  const goToPageView = () => {
    navigate(`/post-o-page/${post.id}`);
  };

  const getUsersSharedWith = () => {
    getDocs(
      query(
        collection(db, 'sharedPosts'),
        where('post', '==', post.id),
        where('sharedBy', '==', currentUser?.uid)
      )
    ).then(async response => {

      const sharedPosts = response.docs.map(docu => ({
        ...docu.data(),
        id: docu.id
      }))

      const sharedPostsWithUser = await Promise.all(
        sharedPosts.map(async sp => {

          const sharedWith = await getDoc(doc(db, 'users', sp.sharedWith))

          return {
            ...sp,
            sharedWith: sharedWith.data()
          } 
        })
      )
      
      setUsersSharedWith(sharedPostsWithUser.map(sp => sp.sharedWith))

    })
  };

  const getUserData = async () => {
    dispatch({ type: "showLoading" });
    const querySnapshot = await getDocs(collection(db, "users"));
    const temp = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      temp.push({ ...doc.data(), id: doc.id });
    });
    //console.log(temp);
    setUserData(temp);
    dispatch({ type: "hideLoading" });
  };

  // function showFeedback(obj, page) {
  //   // check userSelect value (selected user as content or no selection)
  //   // if userSelect = filled, check feedback matches selected user
  //   // if userSelect = null, show all feedback that matches page per feedback obj (with function parameters)
  //   // {post.buys?.map((buy) => {
  //   //                       if (buy.page === page.page)
  //   //                         return (
  //   if (userSelect === null) {
  //     // show all feedback that matches the page
  //   } else {
  //     // show feedback that matches the page and the selected user.id
  //   }
  // }

  function setUser(user) {
    // console.log("setUser clicked, user received as parameter = ", user);
    // console.log("user.user.id = ", user.user.id);
    setUserSelect(user);
    setUserSelectId(user.id);
    setUserSet(true);
  }

  const showUserSelection = () => {
    if (userSelect.displayName === "all") {
      return "Show All";
    } else {
      return userSelect.displayName;
    }
  };

  function showAllSet() {
    setUserSelect(allUsers2);
    setUserSelectId("");
    setUserSet(false);
  }

  useEffect(() => {
    getData();
    getUserData();
  }, []);

  useEffect(() => {
    if (post) {
      getUsersSharedWith();
      checkRequests();
    }
  }, [post]);

  useEffect(() => {
    const handleScrollToY = async () => {
      if (scrollRef.current && location.state?.scrollY) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        scrollRef.current.scrollTo({ top: location.state.scrollY, behavior: 'smooth' });
      }
    };
  
    handleScrollToY();
  }, [location.state?.scrollY])

  return (
    <DefaultLayout scrollRef={scrollRef} onScroll={handleScroll}>
      <div className="d-flex justify-content-center px-1">
        {post && (
          <>
            <Card>
                <Card.Header as="h5">
                  <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
                    <div style={{position: 'absolute', left: 0}}>
                      <IoCaretBackSharp
                        size={17}
                        color="dark"
                        style={{ cursor: "pointer" }}
                        onClick={goToProfileGrid}
                      />
                    </div>
                    {post.description}
                  </div>
                </Card.Header>
              <Card.Body>
                <Card.Title as="h6" style={{textAlign: 'center'}}>
                  <span className="text-secondary">uploaded on </span>
                  <span className="text-muted">
                    {moment(post.createdAt).format('YYYY-MM-DD')}
                  </span>
                </Card.Title>
                <div className="text-center">
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="shadow-sm rounded" style={{display: 'flex', padding: '8px'}}>
                      <div className="bg-light border-light rounded shadow-sm" style={{padding: '8px', cursor: 'pointer', marginRight: '16px'}}
                        onClick={() =>
                          navigate(
                            `/post-o-table/${post.id}${userSelectId}`
                          )
                        }
                      >
                        <FaTable
                          size={18}
                          color="dark"
                        />
                      </div>
                      <div
                        className={`bg-light border-light rounded shadow-s ${
                          gridToggled
                            ? "border border-secondary"
                            : "border-light"
                        }`}
                        style={{padding: '8px', cursor: 'pointer'}}
                        onClick={() => {
                          setGridToggled(gridToggled ? false : true);
                        }}
                      >
                        <BsFillGrid3X3GapFill
                          size={18}
                          color="dark"
                        />
                      </div>
                    </div>
                    <div className="shadow-sm rounded" style={{display: 'flex', padding: '8px'}}>
                      
                        <OverlayTrigger
                            rootClose={true}
                            trigger="click"
                            placement="bottom"
                            overlay={<Popover style={{marginTop: '10px'}}><SharedWithPopover post={post}/></Popover>}
                          >
                            <div className="bg-light border-light rounded shadow-sm" style={{padding: '8px', cursor: 'pointer', marginRight: '16px'}}>
                              <FaThList
                                size={18}
                                color="dark"
                              />
                            </div>
                          </OverlayTrigger>
                      <Button
                        variant="outline-dark"
                        onClick={() => navigate(`/sharepost/${post.id}`)}
                      >
                        Share
                      </Button>
                    </div>
                  </div>

                    <div className="row text-center mt-2">
                      {pages.map((page) => {
                        let alreadyBuy = false;
                        let alreadyUrgent = false;
                        let alreadyLiked = false;
                        let alreadyComment = false;
                        let alreadyOnHold = false;
                        let showFeedbackBar = false;
                        if (userSelect.displayName === "all") {
                          if (buyCount(page.page) > 0) {
                            alreadyBuy = true;
                          }
                          if (urgentCount(page.page) > 0) {
                            alreadyUrgent = true;
                          }
                          if (likeCount(page.page) > 0) {
                            alreadyLiked = true;
                          }
                          if (commentCount(page.page) > 0) {
                            alreadyComment = true;
                          }
                          if(onHoldCount(page.page) > 0){
                            alreadyOnHold = true;
                          }
                        } else {
                          if (userBuyCount(page.page, userSelect) > 0) {
                            alreadyBuy = true;
                          }
                          if (userUrgentCount(page.page, userSelect) > 0) {
                            alreadyUrgent = true;
                          }
                          if (userLikeCount(page.page, userSelect) > 0) {
                            alreadyLiked = true;
                          }
                          if (userCommentCount(page.page, userSelect) > 0) {
                            alreadyComment = true;
                          }
                          if(userOnHoldCount(page.page, userSelect) > 0) {
                            alreadyOnHold = true
                          }
                        }
                        if (
                          alreadyBuy === true ||
                          alreadyLiked === true ||
                          alreadyComment === true || 
                          alreadyOnHold === true
                        ) {
                          showFeedbackBar = true;
                        }
                        return (
                          <div
                            key={page.page}
                            className={`${
                              gridToggled
                                ? "col-6 col-sm-4 col-md-4 col-lg-3 col-xxl-2"
                                : "col-sm-6 col-md-6 col-lg-6 col-xxl-4"
                            } mt-5 mb-2`}
                          >
                            <div className="d-inline-flex position-relative justify-content-center mx-0">
                            <Image
                              fluid thumbnail
                              src={page.imageURL}
                              style={{cursor: 'pointer'}}
                              onClick={
                                () => setShowModal(page)
                                  // navigate(
                                  //   `/post-o-page/${post.id}#${page.page}`, {state: {scrollY}}
                                  // ) // need to include this in url for getting userSelect.user.id into Page view to show filtered content, @${userSelect.user.id}
                              }
                            />
                              <div>
                                <MDBBadge
                                  pill
                                  color="light"
                                  className="position-absolute bottom-100 my-1 start-50 translate-middle-x p-2 bg-info border-dark shadow-sm text-dark"
                                >
                                  {page.page}
                                  <span className="visually-hidden">
                                    Page Number
                                  </span>
                                </MDBBadge>
                              </div>

                              {post.flags.map((flag) => {
                                if (flag.page === parseInt(page.page))
                                  return (
                                    <div key={flag.mapKey}>
                                      <MDBBadge
                                        pill
                                        color="info"
                                        className="position-absolute bottom-0 start-50 translate-middle-x p-2 bg-info border-dark shadow-sm"
                                      >
                                        {/* {flagCount(page.page)} */}
                                        <span className="visually-hidden">
                                          New alerts
                                        </span>
                                      </MDBBadge>
                                    </div>
                                  );
                              })}
                            </div>
                            {/* here is where i need to conditionally show the below div
                        containing icons and their badges/counts - that way the
                        icons colors can be fixed */}
                            {/* set a flag variable true or false above in page map / post check and use that variable to render div below */}
                          <div style={{display: 'flex', justifyContent: 'space-around'}} className={`bg-white shadow-sm rounded p-1 ${
                            alreadyUrgent === true ? "border border-warning" : ""
                          }`}>        
                            {post.urgents.map((urgent) => {
                              if (userSelect.displayName === "all") {
                                if (urgent.page === parseInt(page.page))
                                  return (
                                    <div key={urgent.mapKey}>
                                      {/* <MDBBadge
                                    pill
                                    color="warning"
                                    className="position-absolute translate-middle-x my-4 p-1 px-2 text-dark"
                                    style={{
                                      border: "1px solid #FFA500",
                                    }}
                                  >
                                    {urgentCount(page.page)}
                                    <span className="visually-hidden">
                                      New alerts
                                    </span>
                                  </MDBBadge> */}

                                      {/* <MDBBadge
                              color="info"
                              className="position-absolute top-0 start-100 translate-middle-x  p-2 bg-info border border-muted rounded-circle"
                            >
                              <span className="visually-hidden">
                                New alerts
                              </span>
                            </MDBBadge> */}
                                    </div>
                                  );
                              } else {
                                if (
                                  urgent.page === parseInt(page.page) &&
                                  urgent.id === userSelect.id
                                )
                                  return <div key={urgent.id}></div>;
                              }
                            })}
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <AiFillHeart
                                size={28}
                                color={alreadyLiked ? "red" : "lightGray"}
                                // ref={likeHeart}
                              />
                              {alreadyLiked && (
                                <div>
                                  <MDBBadge
                                    pill
                                    color="white"
                                    className="text-dark"
                                    style={{
                                      border: "1px solid #C0C0C0",
                                    }}
                                  >
                                    {userSelect.displayName === "all" ? likeCount(page.page) : userLikeCount(page.page, userSelect)}
                                    <span className="visually-hidden">
                                      New alerts
                                    </span>
                                  </MDBBadge>
                                </div>
                              )}
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <FaHandPaper
                                size={28}
                                color={alreadyOnHold ? "#FFAB01" : "lightGray"}
                              />
                              {alreadyOnHold && (
                                <div>
                                  <MDBBadge
                                    pill
                                    color="white"
                                    className="text-dark"
                                    style={{
                                      border: "1px solid #C0C0C0",
                                    }}
                                  >
                                    {userSelect.displayName === "all" ? onHoldCount(page.page) : userOnHoldCount(page.page, userSelect)}
                                    <span className="visually-hidden">
                                      New alerts
                                    </span>
                                  </MDBBadge>
                                </div>
                              )}
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <AiFillDollarCircle
                                size={28}
                                color={alreadyBuy ? "blue" : "lightGray"}
                              />
                              {alreadyBuy && (
                                <div>
                                  <MDBBadge
                                    pill
                                    color={
                                      alreadyUrgent && post.buys?.length === 1
                                        ? "warning"
                                        : "white"
                                    }
                                    className="text-dark"
                                    style={{
                                      border: `1px solid ${
                                        alreadyUrgent && post.buys?.length > 1
                                          ? "#FFC107 "
                                          : "#C0C0C0 "
                                      }`,
                                    }}
                                  >
                                    {userSelect.displayName === "all" ? buyCount(page.page) : userBuyCount(page.page, userSelect)}
                                    <span className="visually-hidden">
                                      New alerts
                                    </span>
                                  </MDBBadge>
                                </div>
                              )}
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <FaComment
                                size={28}
                                color={alreadyComment ? "pink" : "lightGray"}
                              />
                              {alreadyComment && (
                                <div>
                                  <MDBBadge
                                    pill
                                    color="white"
                                    className="text-dark"
                                    style={{
                                      border: "1px solid #C0C0C0",
                                    }}
                                  >
                                    {userSelect.displayName === "all" ? commentCount(page.page) : userCommentCount(page.page, userSelect)}
                                    <span className="visually-hidden">
                                      New alerts
                                    </span>
                                  </MDBBadge>
                                </div>
                              )}
                            </div>
                          </div>
                          </div>
                        );
                      })}
                    </div>

                </div>
                {/* <Card.Text>
                  With supporting text below as a natural lead-in to additional
                  content.
                </Card.Text> */}
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </>
        )}
      </div>
      {showModal !== null && <OutgoingPreviewModal post={post} page={showModal} show={showModal} setShow={setShowModal} getData={getData}/>}
    </DefaultLayout>
  );
}

export default PostDescOgrid;
