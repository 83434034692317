import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Plans from "./pages/Plans";
import Register from "./pages/Register";
import Login from "./pages/Login";
import NavDarkReact from "./components/NavDarkReact";

import Account from "./pages/Account";
import AddPost from "./pages/AddPost";
import Sharepost from "./pages/Sharepost";
import Shares from "./pages/Shares";
import ProfileGrid from "./pages/ProfileGrid";
import PostDescOgrid from "./pages/PostDescOgrid";
import PostDescOpage from "./pages/PostDescOpage";
import PostDescSgrid from "./pages/PostDescSgrid";
import PostDescSpage from "./pages/PostDescSpage";

import Calendar from "./pages/Calendar";
import Drive from "./pages/Drive";

import OrderStatusSpage from "./pages/OrderStatusSpage";
import OrderStatusOpage from "./pages/OrderStatusOpage";
import PostDescOtable from "./pages/PostDescOtable";
import StatusSclientGrid from "./pages/StatusSclientGrid";
import LikedPagesClient from "./pages/LikedPagesClient";
import CollectionGridClient from "./pages/CollectionGridClient";
import ArchivedShares from "./pages/ArchivedShares";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import PublicRoute from "./components/routes/PublicRoute";
import Notifications from "./pages/Notifications";
import ArchivedAcquisitions from "./pages/ArchivedAcquisitions";
import Profile from "./pages/Profile";
import Connections from "./pages/Connections";
import AcquisitionStatusBuyer from "./pages/AcquisitionsStatusBuyer";
import AcquisitionStatusSeller from "./pages/AcquisitionsStatusSeller";
import AuthRoute from "./components/routes/AuthRoute";
import ForgotPassword from "./pages/ForgotPassword";
import Help from "./pages/Help";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import WhoAreYou from "./pages/WhoAreYou";
import { NavBar } from "./components/organisms/NavBar";
import DashboardNew from "./pages/DashboardNew";

function App() {
  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
          rel="stylesheet"
        />
      </head>
      {/*<NavDarkReact />*/}
      <NavBar />
      <ToastContainer />
      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 2000,
        }}
      />
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard-new"
          element={
            <ProtectedRoute>
              <DashboardNew />
            </ProtectedRoute>
          }
        />

        <Route
          path="/register"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />

        <Route
          path="/plans"
          element={
            <AuthRoute>
              <Plans />
            </AuthRoute>
          }
        />
        <Route
          path="/whoareyou"
          element={
            <AuthRoute>
              <WhoAreYou />
            </AuthRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route path="/help" element={<Help />} />

        <Route
          path="/calendar"
          element={
            <ProtectedRoute>
              <Calendar />
            </ProtectedRoute>
          }
        />
        <Route
          path="/drive"
          element={
            <ProtectedRoute>
              <Drive />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addpost"
          element={
            <ProtectedRoute>
              <AddPost />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile-grid/"
          element={
            <ProtectedRoute>
              <ProfileGrid />
            </ProtectedRoute>
          }
        />
        <Route
          path="/shares/"
          element={
            <ProtectedRoute>
              <Shares />
            </ProtectedRoute>
          }
        />

        <Route
          path="/archived-shares/"
          element={
            <ProtectedRoute>
              <ArchivedShares />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sharepost/:id"
          element={
            <ProtectedRoute>
              <Sharepost />
            </ProtectedRoute>
          }
        />
        <Route
          path="/post-o-page/:id"
          element={
            <ProtectedRoute>
              <PostDescOpage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/post-o-grid/:id"
          element={
            <ProtectedRoute>
              <PostDescOgrid />
            </ProtectedRoute>
          }
        />
        <Route
          path="/post-o-table/:id"
          element={
            <ProtectedRoute>
              <PostDescOtable />
            </ProtectedRoute>
          }
        />
        <Route
          path="/post-s-page/:id"
          element={
            <ProtectedRoute>
              <PostDescSpage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/post-s-grid/:id"
          element={
            <ProtectedRoute>
              <PostDescSgrid />
            </ProtectedRoute>
          }
        />
        <Route
          path="/acquisition-status-seller"
          element={
            <ProtectedRoute>
              <AcquisitionStatusSeller />
            </ProtectedRoute>
          }
        />
        <Route
          path="/acquisition-status-buyer"
          element={
            <ProtectedRoute>
              <AcquisitionStatusBuyer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/status-client-grid/"
          element={
            <ProtectedRoute>
              <StatusSclientGrid />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order-s-status/:id"
          element={
            <ProtectedRoute>
              <OrderStatusSpage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order-o-status/:id"
          element={
            <ProtectedRoute>
              <OrderStatusOpage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-feedback/"
          element={
            <ProtectedRoute>
              <LikedPagesClient />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route path="/profile/:userId" element={<Profile />} />
        <Route
          path="/collection-client/"
          element={
            <ProtectedRoute>
              <CollectionGridClient />
            </ProtectedRoute>
          }
        />

        <Route
          path="/connections"
          element={
            <ProtectedRoute>
              <Connections />
            </ProtectedRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <ProtectedRoute>
              <Notifications />
            </ProtectedRoute>
          }
        />
        <Route
          path="/archived-acquisitions"
          element={
            <ProtectedRoute>
              <ArchivedAcquisitions />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
