import React from "react";
import { CustomButton } from "./CustomButton";

/**
 * @example
   <DashboardCard
        title={"Outgoing Capsules"}
        description={
          "What is a capsule? It’s a preview of an art exhibition, a singular artwork offering, and more."
        }
        callToActionText={"See All"}
        type={"primary"}
      >
        <div className="w-full bg-gray-500 h-36">children goes here</div>
      </DashboardCard>
 */

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} [props.description]
 * @param {string} [props.callToActionText]
 * @param {function():void} [props.onCallToAction]
 * @param {React.ReactElement | string | string[]} [props.children]
 * @param {function(string):void} [props.onClick]
 * @param {"primary"|"secondary"} props.type
 */
export function DashboardCard({
  onClick,
  type = "primary",
  title,
  description,
  callToActionText,
  onCallToAction,
  children,
}) {
  return (
    <div
      className={`p-3 md:!p-5 rounded-lg shadow ${
        type === "primary" ? " bg-main text-white" : ""
      }`}
    >
      <div className="flex flex-col mb-3 sm:flex-row space-between">
        <div className="mr-auto">
          <h1 className="font-primary text-lg py-1">{title}</h1>
          {description ? (
            <p className="font-light text-base">{description}</p>
          ) : null}
        </div>

        {callToActionText ? (
          <CustomButton
            onClick={onCallToAction}
            variant={type === "secondary" ? "primary" : "secondary"}
            className="w-fit my-2 sm:my-auto sm:w-36 sm:ml-4 h-10 text-base !px-5"
          >
            {callToActionText}
          </CustomButton>
        ) : null}
      </div>
      {children}
    </div>
  );
}
