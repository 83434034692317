import React, { useRef, useState } from "react";
import Loader from "./Loader";
import CreatableSelect from 'react-select/creatable';
import { Link } from "react-router-dom";
import { FaStar, FaTrash } from "react-icons/fa";
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useAuth } from "../context/AuthContext";
import moment from "moment";
import { toast } from 'react-toastify';
import { sendNotification } from "../utils/notification";
import AdminInviteModal from "./AdminInviteModal";


export const ConnectionListItem = ({ item, type, refreshList }) => {

  const { currentUserRealTime: currentUser } = useAuth()

  const [loading, setLoading] = useState(false)
  const [loadingRespose, setLoadingResponse] = useState(false)

  const handleDelete = () => {
    setLoading(true)
    deleteDoc(doc(
      db,
      'users',
      currentUser?.uid,
      !!item.nonUser ? 'notMembersInvited' : type,
      !!item.nonUser ? item?.name : item?.id
    ))
      .then(() => {
        if(!!item?.nonUser === false){
          deleteDoc(doc(
            db,
            'users',
            item?.id,
            type,
            currentUser?.uid
          ))
            .then(() => {
              toast.success('User removed succesfully')
              refreshList()
              setLoading(false)
            }).catch(err => console.log(err))
        }else{
          toast.success('User removed succesfully')
          refreshList()
          setLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
        toast.error('Error while removing user. Try again!')
        setLoading(false)
      })
  }

  const handleInviteChange = (option) => {
    setLoading(true)

    if(option === 'accepted'){
      updateDoc(doc(db, 'users', currentUser?.uid, type, item?.id), {
        status: option
      }).then(() => {
        updateDoc(doc(db, 'users', item?.id, type, currentUser?.uid), {
          status: option
        }).then(async () => {
          toast.success(`Invite ${option}`)
          await sendNotification(currentUser?.uid, item?.id, 'connectionResponse')
        }).catch(err => {
          console.log(err)
          toast.error('Error while responding invitation. Please, try again!')
        })
      }).catch(err => {
        console.log(err)
        toast.error('Error while responding invitation. Please, try again!')
      }).finally(() => {
        refreshList()
        setLoading(false)
      })
    }else{
      deleteDoc(doc(db, 'users', currentUser?.uid, type, item?.id)).then(() => {
        deleteDoc(doc(db, 'users', item?.id, type, currentUser?.uid)).then(async () => {
          toast.success(`Invite ${option}`)
          await sendNotification(currentUser?.uid, item?.id, 'connectionResponse')
        }).catch(err => {
        }).catch(err => {
          console.log(err)
          toast.error('Error while responding invitation. Please, try again!')
        })
      }).catch(err => {
        console.log(err)
        toast.error('Error while responding invitation. Please, try again!')
      }).finally(() => {
        refreshList()
        setLoading(false)
      })
    }

    

  }

  const handleFav = () => {
    updateDoc(doc(db, 'users', currentUser?.uid, type, item?.id), {
      isFav: !item?.isFav
    })
      .then(() => refreshList())
      .catch(err => 
        toast.error(`Error to ${!item.isFav ? 'favorite' : 'unfavorite'}. Please, try again!`)
      )
  }

  return(
    <div style={{border: '1px solid rgba(0, 0, 0, 0.175)', margin: '2px 0', borderRadius: '10px', padding: '0 10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <p style={{margin: 0}}>
        {item?.name}
        <span style={{color: 'rgba(33,37,41, 0.3)', fontSize: '14px'}}>{` (${item?.sub ? item?.sub : 'Not Registered'})`}</span>
      </p>
      {item?.nonUser || !item?.sub || (item?.status === 'pending' && (item?.createdBy === currentUser?.uid)) ? 
        <div className="d-flex align-items-center justify-content-center">
          <p style={{margin: 0, fontSize: '12px', color: '#FFC400', marginRight: '10px'}}>Pending</p>
          {loading ? (<Loader/>) : (
            <FaTrash
              size={10}
              onClick={handleDelete}
              style={{ cursor: "pointer" }}
            />
          )}
        </div>
      :
        item?.status === 'pending' && !(item?.createdBy === currentUser?.uid) ?
          <>
            {loading ? (<Loader/>) : 
              <div className="d-flex align-items-center justify-content-center">
                <Link
                  onClick={() => handleInviteChange('accepted')}
                  style={{
                      textDecoration: 'none',
                      fontSize: '12px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#36B37E',
                  }}
                >
                  Accept
                </Link>
                <p style={{margin: 0, padding: '0 10px'}} className="text-secondary">|</p>
                <Link
                  onClick={() => handleInviteChange('rejected')}
                  style={{
                      textDecoration: 'none',
                      fontSize: '12px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#FF5630',
                  }}
                >
                  Decline
                </Link>
              </div>
            }
          </>
      :
        <>
          {loading ? (<Loader/>) : (
            <div className="d-flex align-items-center justify-content-center">
              <FaStar
                size={14}
                onClick={handleFav}
                style={{ cursor: "pointer", marginRight: '10px' }}
                color={item?.isFav ? '#FFC400' : "rgba(33,37,41, 0.3)"}
              />
              <FaTrash
                size={12}
                onClick={handleDelete}
                style={{ cursor: "pointer" }}
              />
            </div>
          )}
        </>
      }
    </div>
  )
}

function ConnectionList({ items, options, type, refreshList }) {

  const { currentUser, isConnoisseur, isVendor } = useAuth()

  const selectRef = useRef(null)

  const [loading, setLoading] = useState(false)

  const [selected, setSelected] = useState([])

  const [searchOption, setSearchOption] = useState(null)

  const filterOptions = options.filter(option => option?.label.includes(searchOption))

  const styles = {
    control: (styles) => ({...styles, borderRadius: '10px'}),
    container: (styles) => ({...styles, width: '100%', marginRight: '10px'})
  }

  const isEmail = (string) => {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(string);
  }

  const userAlreadyInvited = async (email, type) => {
    const notMemberDoc = await getDoc(doc(db, 'users', currentUser?.uid, type, email))
    return notMemberDoc.exists()
  }

  const handleInvite = async (users) => {

      if(selected.length === 0) return

      setLoading(true)

      const newUsers = users
        ?.filter(user => user?.__isNew__)
        .map(user => ({...user, email: user?.value.toLowerCase()}))

      await Promise.all(newUsers.map(async user => {
        await inviteNewUser(user)
      }))

      const alreadyUsers = users.filter(user => !user?.__isNew__)

      await Promise.all(alreadyUsers.map(async user => {
        const alreadyUserDoc = await getDoc(doc(db, 'users', user?.value))
        const alreadyUser = {...alreadyUserDoc.data(), id: alreadyUserDoc.id}

        const alredyInvited = await userAlreadyInvited(alreadyUser?.email, type)

        if(alredyInvited){
          toast.error(`Invite alredy sent to @${alreadyUser?.username}`)
        }else{
          await inviteAlreadyUser(alreadyUser)
        }
      }))

      refreshList()

      setLoading(false)

  }

  const inviteAlreadyUser = async (user) => {

    const invitedUser = {
      sub: user?.role,
      name: user?.displayName,
      email: user?.email,
      username: user?.username,
      isFav: false,
      createdAt: moment().format(),
      createdBy: currentUser?.uid,
      status: 'pending',
    }

    const requesterUser = {
      sub: isConnoisseur() ? 'Seller' : 'Buyer',
      name: currentUser?.displayName,
      email: currentUser?.email,
      username: currentUser?.username,
      isFav: false,
      createdAt: moment().format(),
      createdBy: currentUser?.uid,
      status: 'pending',
    }

    setDoc(doc(db, 'users', currentUser?.uid, type, user?.id), invitedUser)
      .then(response => {
        setDoc(doc(db, 'users', user?.id, type, currentUser?.uid), requesterUser)
        .then(async response => {
          toast.success(`Invite sent to @${user?.username}`)
          if(selectRef.current) selectRef.current.clearValue()
          await sendNotification(currentUser?.uid, user?.id, 'connectionInvite')
        })
        .catch(err => {
          console.log(err)
          toast.error(`Failed to send invite to @${user?.username}`)
          deleteDoc(doc(db, 'users', currentUser?.uid, type, user?.id))
            .catch(err => console.log(err))
        })
      })
      .catch(err => {
        console.log(err)
        toast.error(`Failed to send invite to @${user?.username}`)
      })

  }

  const inviteNewUser = async (user) => {
    
    const isEmailValid = isEmail(user?.email)

    if(!isEmailValid){
      toast.error(`${user?.email} is not a valid email`)
      return
    }

    const alredyInvited = await userAlreadyInvited(user?.email, 'notMembersInvited')

    if(alredyInvited){
      toast.error(`Invite alredy sent to ${user?.email}`)
      return
    }

    if(user?.email == currentUser?.email){
      toast.error(`Cannot invite your own email (${user?.email})`)
      return
    }

    const usersDocs = await getDocs(query(collection(db, 'users'), where('email', '==', user?.email)))

    const alreadyUser = usersDocs.docs[0]

    if(alreadyUser){
      inviteAlreadyUser({...alreadyUser.data(), id: alreadyUser.id})
      return
    }

    const newUser = {
      type: type,
      createdAt: moment().format(),
      invitedByAdminAs: !!user?.role ? user?.role : null
    }

    setDoc(doc(db, 'users', currentUser?.uid, 'notMembersInvited', user?.email), newUser)
      .then(response => {
        toast.success(`Invite sent to ${user?.email}`)
        if(selectRef.current) selectRef.current.clearValue()
      })
      .catch(err => {
        console.log(err)
        toast.error(`Failed to send invites to ${user?.email}`)
      })

  }

  const [showAdminModal, setShowAdminModal] = useState(false)

  const checkUserRole = () => {
    if(selected.length === 0) return

    setLoading(true)

    const newUsers = selected?.filter(user => user?.__isNew__)

    if(type === 'vendors'){
      handleInvite(selected.map(obj => ({...obj, role: 'vendor'})))
    }else if(newUsers.length !== 0){
      setShowAdminModal(true)
    }else{
      handleInvite(selected)
    }
  }

  const formatCreateLabel = (inputValue) => `Invite "${inputValue}"`

  return (
    <>
      {!isVendor() &&
        <div className="d-flex mt-1 w-full align-items-center mb-3">
          <CreatableSelect
            ref={selectRef}
            isMulti
            closeMenuOnSelect={false}
            options={filterOptions}
            onChange={obj => setSelected([...obj])}
            styles={styles}
            isClearable
            formatCreateLabel={formatCreateLabel}
            onInputChange={(value) => value === '' ? setSearchOption(null) : setSearchOption(value)}
          />
          {loading ? 
            <Loader/>
          : 
            <Link
              onClick={checkUserRole}
              style={{
                  textDecoration: 'none',
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
              }}
            >
              {type === 'vendors' ? 'Add Vendors' : 'Add To Network'}
            </Link>
          }
        </div>
      }
      {(!items || items.length === 0) && (
        <div className="d-flex align-items-center justify-content-center">
          <p style={{margin: 0, fontSize: '12px'}} className="text-secondary">No members to show</p>
        </div>
      )}
      {items?.map(item => (<ConnectionListItem key={item.name} item={item} type={type} refreshList={refreshList}/>))}
      <AdminInviteModal invites={selected} show={showAdminModal} setShow={setShowAdminModal} handleInvite={handleInvite} setLoading={setLoading}/>
    </>
  );
}

export default ConnectionList;
