import React, { useState, useEffect } from "react";
import DefaultLayout from "../components/DefaultLayout";
import { useSelector, useDispatch } from "react-redux";
import Input from "../components/Input";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { app, db } from "../firebaseConfig";
import moment from "moment";
import { useAuth } from "../context/AuthContext";

const Register = () => {
  const { loading } = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { currentUser } = useAuth();

  const register = async () => {
    dispatch({ type: "showLoading" });

    const name = firstName + " " + lastName;
    const username = name.trim();

    if (password.length === 0) {
      dispatch({ type: "hideLoading" });
      toast.error("Password required. Please enter a password.");
      return;
    }

    if (password.length < 6) {
      dispatch({ type: "hideLoading" });
      toast.error(
        "Password needs to be at least 6 characters long. Please re-enter a password."
      );
      return;
    }

    const auth = getAuth(app);
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        await updateProfile(user, { displayName: name });

        const userData = {
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          displayName: name,
          name: name,
          email: user.email,
          username: name,
          profilePicUrl: "",
          bio: "Hi, I am using Romulus",
        };

        await setDoc(doc(db, "users", user.uid), userData);

        await setDoc(doc(db, "users", user.uid, "public", "profile"), {
          name: name,
        });

        await setDoc(doc(db, "usernames", username), {
          isAvailable: false,
          userId: user?.uid,
          createdAt: moment().format("YYYY-MM-DD hh:mm:ss a"),
        });

        if (currentUser) {
          dispatch({ type: "hideLoading" });
          navigate("/login");
        }
      })
      .catch((error) => {
        dispatch({ type: "hideLoading" });
        if (error.code === "auth/email-already-in-use")
          toast.error("E-mail already in use");
        else toast.error("Something went wrong. Please try again.");
      });
  };

  const goToLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout>
      {" "}
      <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center pb-5 mb-5">
        <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 pb-5 mb-5">
          {" "}
          <Card className="mb-5">
            <span className="text-center">
              <Card.Header as="h4">Register</Card.Header>
            </span>
            <Card.Body>
              <span className="text-center">
                <Card.Title></Card.Title>
              </span>
              <div className="d-flex justify-content-center pt-2 pb-3">
                <div className="text-center">
                  <Input
                    label="First Name"
                    type="name"
                    value={firstName}
                    setValue={setFirstName}
                  />
                  <Input
                    label="Last Name"
                    type="name"
                    value={lastName}
                    setValue={setLastName}
                  />
                  <Input
                    label="Email"
                    type="email"
                    value={email}
                    setValue={setEmail}
                  />
                  <Input
                    label="Password"
                    type="password"
                    value={password}
                    setValue={setPassword}
                  />
                  {/* <Input
                  label="Confirm"
                  type="password"
                  value={confirmPassword}
                  setValue={setConfirmPassword}
                /> */}

                  <div className="d-grid">
                    <Button variant="outline-dark" onClick={register}>
                      {loading ? <Loader /> : "Register"}
                    </Button>
                  </div>

                  <div className="d-grid mt-2">
                    <Button variant="outline-secondary" onClick={goToLogin}>
                      Need to login?
                    </Button>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Register;
