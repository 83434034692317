import React, {
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import DefaultLayout from "../components/DefaultLayout";
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { MDBBadge } from "mdb-react-ui-kit";

import {
  AiFillFilter,
} from "react-icons/ai";

import { IoCaretBackSharp } from "react-icons/io5";

import { FaThList } from "react-icons/fa";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { toast } from "react-toastify";

import ExportPdfButton from "../components/ExportPdfButton";
import InputFeedback from "../components/InputFeedback";
import ImagePreviewModal from "../components/ImagePreviewModal";
import TableFilterModal from "../components/TableFilterModal";
import SharedWithPopover from "../components/SharedWithPopover";
import { Popover } from "react-bootstrap";

function PostDescOtable() {

  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [post, setPost] = useState()
  const [pages, setPages] = useState()

  const [editingDetailKey, setEditingDetailKey] = useState(null)
  const [hoverDetailIndex, setHoverDetailIndex] = useState({ hoverRowIndex: null, hoverColIndex: null })
  const [editingNoteKey, setEditingNoteKey] = useState(null)
  const [hoverNoteIndex, setHoverNoteIndex] = useState({ hoverRowIndex: null, hoverColIndex: null })

  const [showFilterModal, setShowFilterModal] = useState(false)
  const [showImagePreviewModal, setShowImagePreviewModal] = useState(null)

  const [userSelected, setUserSelected] = useState({id: 'all', displayName: 'All'})

  const [pagesWithFeedback, setPagesWithFeedback] = useState([])

  const [showAllPages, setShowAllPages] = useState(true)
  const [filters, setFilters] = useState({
    likes: true,
    buys: true,
    urgents: true,
    comments: true,
    onHolds: true
  })
  
  const getData = () => {
    dispatch({ type: "showLoading" });
    getDoc(doc(db, "posts", params.id))
      .then((response) => {
        setPost({ ...response.data(), id: response.id });

        dispatch({ type: "hideLoading" });
      })
      .catch(() => {
        dispatch({ type: "hideLoading" });
      })
  }

  const getPages = (post) => {
    getDocs(query(collection(db, 'posts', post?.id, 'pages'), orderBy('page', 'asc'))).then(res => {
      setPages(res.docs.map(docu => ({...docu.data(), id: docu.id})))
    })
  }

  const handleEditingDetailCellClick = (mapKey) => setEditingDetailKey(mapKey) 
  const handleEditingNoteCellClick = (mapKey) => setEditingNoteKey(mapKey)

  const handleDetailsChange = async (page, newValue) => {

    const pageDoc = await getDoc(doc(db, 'posts', post?.id, 'pages', page))
    const postPage = pageDoc.data()

    if(postPage.text !== newValue){
      await updateDoc(doc(db, 'posts', post?.id, 'pages', page), {
        text:  newValue
      }).then(() => {
        toast.success("Details saved successfully");
        getPages(post)
      }).catch((error) => {
        toast.error("Error saving details");
      })
    }
    setEditingDetailKey(null)
  }

  const handleNotesChange = async (page, newValue) => {

    const pageDoc = await getDoc(doc(db, 'posts', post?.id, 'pages', page))
    const postPage = pageDoc.data()
  
    if(postPage.notes !== newValue){  
      await updateDoc(doc(db, 'posts', post?.id, 'pages', page), {
        notes:  newValue
      }).then(() => {
        toast.success("Notes saved successfully");
      }).catch((error) => {
        toast.error("Error saving notes");
      });
    }
    setEditingNoteKey(null)
  }

  const getPagesWithFeedback = (post) => {
    
    const pages = []
    if(post.likes){
      if(userSelected.id !== 'all'){
        post.likes
          .filter(like => like.id === userSelected.id)
          .map(like => pages.push(like.page))
      }else{
        post.likes?.map(like => pages.push(like.page))
      }
    }
    if(post.buys){
      if(userSelected.id !== 'all'){
        post.buys
          .filter(buy => buy.id === userSelected.id)
          .map(buy => pages.push(buy.page))
      }else{
        post.buys?.map(buy => pages.push(buy.page))
      }
    }
    if(post.comments){
      if(userSelected.id !== 'all'){
        post.comments
          .filter(comment => comment.id === userSelected.id)
          .map(comment => pages.push(comment.page))
      }else{
        post.comments?.map(comment => pages.push(comment.page))
      }
    }
    if(post.onHold){
      if(userSelected.id !== 'all'){
        post.onHold
          .filter(oh => oh.id === userSelected.id)
          .map(oh => pages.push(oh.page))
      }else{
        post.onHold?.map(oh => pages.push(oh.page))
      }
    }
    setPagesWithFeedback([...new Set(pages)])
  }

  useEffect(() => {
    if(post) {
      getPages(post)
      getPagesWithFeedback(post)
    }
  }, [post, userSelected])

  useEffect(() => {
    getData()
  },[])

  return (
    <DefaultLayout>
      <div className="d-flex justify-content-center">
        <Card style={{width: '100%'}}>
          <Card.Header as="h5">
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
              <div style={{position: 'absolute', left: 0}}>
                <IoCaretBackSharp
                  size={17}
                  color="dark"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/post-o-grid/${post?.id}`)}
                />
              </div>
              {post?.description}
            </div>
          </Card.Header>
            <Card.Body style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
              <Card.Title as="h6">
                <span className="text-secondary">uploaded on </span>
                <span className="text-muted">
                {(post?.createdAt || post?.createdOn ||"-").split(" ")[0]}
                </span>
              </Card.Title>
              <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <MDBBadge color="white" className="shadow-sm p-2 d-flex">
                  <div
                    className="p-2 bg-light rounded shadow-sm d-flex align-items-center"
                    onClick={() => setShowFilterModal(!showFilterModal)}
                    style={{ cursor: "pointer" }}
                  >
                    <AiFillFilter
                      size={18}
                      color={userSelected.id !== 'all' ? "purple" : "#332D2D"}
                    />
                    <span style={{fontSize: 15, fontStyle: 'italic', color: 'black', fontWeight: '380'}}>- {userSelected.displayName}</span>
                  </div>
                </MDBBadge>
                <MDBBadge color="white" className="shadow-sm p-2 d-flex align-items-center">
                  <div
                    className="p-2 bg-light rounded shadow-sm d-flex align-items-center"
                    style={{ cursor: "pointer"}}
                  >
                    <OverlayTrigger
                      rootClose={true}
                      trigger="click"
                      placement="bottom"
                      overlay={<Popover style={{marginTop: '10px'}}><SharedWithPopover post={post}/></Popover>}
                    >
                      <span className="">
                        <FaThList
                          size={18}
                          color="black"
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </OverlayTrigger>
                  </div>
                  <Button
                    variant="outline-dark"
                    onClick={() => navigate(`/sharepost/${post.id}`)}
                    style={{margin: '0 15px'}}
                  >
                    Share
                  </Button>
                  <div>
                    <ExportPdfButton
                      post={post}
                      tableId={'table-to-export'}
                      filter={filters}
                    />
                  </div>
                </MDBBadge>
              </div>
              <div style={{display: 'flex', width: '100%', marginTop: '15px'}}>
                <Table
                  variant="light"
                  id="table-to-export"
                >
                  <thead>
                    <tr>
                      <th style={{width: '3vw'}} className="text-center">#</th>
                      <th style={{width: '18vw'}}>Page</th>
                      <th>Details</th>
                      <th className="pe-3">
                        Input
                      </th>
                      <th>Internal Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pages
                      ?.filter(page => !showAllPages ? pagesWithFeedback.includes(parseInt(page.page)) : true)
                      .map(page => {
                      const isEditingDetail = page.page === editingDetailKey
                      const isHoverDetail = hoverDetailIndex.hoverRowIndex === page.page && hoverDetailIndex.hoverColIndex === page.page
                      const isEditingNote = page.page === editingNoteKey
                      const isHoverNote = hoverNoteIndex.hoverRowIndex === page.page && hoverNoteIndex.hoverColIndex === page.page
                      return (
                      <tr>
                        <td className="text-center">{page.page}</td>
                        <td>
                          <img
                            className="img-thumbnail"
                            style={{
                              minWidth: "15vw",
                              maxWidth: '15vw',
                              cursor: "pointer",
                            }}
                            src={page.imageURL}
                            onClick={() => setShowImagePreviewModal(page)}
                          />
                        </td>
                        <td
                          className={`w-25 pt-3 ${isEditingDetail && "bg-white"} ${isHoverDetail && "bg-white"}`}
                          contentEditable
                          onBlur={e => handleDetailsChange(page.id, e.target.textContent)}
                          onClick={() => handleEditingDetailCellClick(page.page)}
                          onMouseEnter={() => setHoverDetailIndex({hoverRowIndex: page.page, hoverColIndex: page.page})}
                          onMouseLeave={() => setHoverDetailIndex({hoverRowIndex: null, hoverColIndex: null})}
                        >
                          <div
                            className="overflow-auto text-start"
                            style={{
                              display: "inline-block",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            {page.text}
                          </div>
                        </td>
                        <td>
                          <div>
                            {filters.likes && <InputFeedback post={post} page={page?.page} type='likes' userSelected={userSelected}/>}
                            {filters.onHolds && <InputFeedback post={post} page={page?.page} type='onHold' userSelected={userSelected}/>} 
                            {filters.buys && <InputFeedback post={post} page={page?.page} type='buys' userSelected={userSelected}/>}
                            {filters.comments && <InputFeedback post={post} page={page?.page} type='comments' userSelected={userSelected}/>}
                          </div>
                        </td>
                        <td
                          key={page.page}
                          className={`w-25 pt-3 ${isEditingNote && "bg-white"} ${isHoverNote && "bg-white"}`}
                          contentEditable
                          onBlur={e => handleNotesChange(page.id, e.target.textContent)}
                          onClick={() => handleEditingNoteCellClick(page.page)}
                          onMouseEnter={() => setHoverNoteIndex({hoverRowIndex: page.page, hoverColIndex: page.page})}
                          onMouseLeave={() => setHoverNoteIndex({hoverRowIndex: null, hoverColIndex: null})}
                        >
                          <div
                            className="overflow-auto text-start"
                            style={{
                              display: "inline-block",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            {page.notes}
                          </div>
                        </td>
                      </tr>
                    )})}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
        </Card>
      </div>
      {showImagePreviewModal !== null && <ImagePreviewModal post={post} page={showImagePreviewModal} show={showImagePreviewModal} setShow={setShowImagePreviewModal}/>}
      {showFilterModal &&
        <TableFilterModal
          show={showFilterModal}
          setShow={setShowFilterModal}
          userSelected={userSelected}
          setUserSelected={setUserSelected} 
          post={post}
          filters={filters}
          setFilters={setFilters}
          showAllPages={showAllPages}
          setShowAllPages={setShowAllPages}
        />
      }
    </DefaultLayout>
  );
}

export default PostDescOtable;
