import { CircleDollarSign, Hand, Heart, MessageCircle } from "lucide-react";
import React, { useMemo } from "react";
/**
 * @param {object} props
 * @param {number} props.count
 * @param {string} props.title
 * @param {"heart"|"money"|"hand"|"dialog"} props.icon
 */
export const FeedbackOverviewListItem = ({ count, title, icon }) => {
  const iconElement = useMemo(() => {
    switch (icon) {
      case "dialog":
        return <MessageCircle strokeWidth="1.5" size={17}></MessageCircle>;
      case "money":
        return (
          <CircleDollarSign strokeWidth="1.5" size={17}></CircleDollarSign>
        );
      case "hand":
        return <Hand strokeWidth="1.5" size={17}></Hand>;
      case "heart":
        return <Heart strokeWidth="1.5" size={17}></Heart>;
      default:
        return <Heart strokeWidth="1.5" size={17}></Heart>;
    }
  }, [icon]);

  return (
    <div className=" text-white text-base flex flex-row">
      <p className="flex-none w-8 font-light text-right">{count}</p>
      <i className="flex-none my-auto font-light ml-5 mr-2">{iconElement}</i>
      <p className="flex-grow font-light ">{title}</p>
    </div>
  );
};
