// @ts-check
import { useCallback, useMemo, useRef, useState } from "react";
import React from "react";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useClickOutsideDetector } from "../../../hooks/useClickOutside";
import { NoAccountTab, buyerTab, sellerTab, vendorTab } from "./constants";
import SearchModal from "../../SearchModal";
import { DesktopNabBarMenu } from "../../templates/navBar/DesktopNavBarMenu";
import { MobileNabBarMenu } from "../../templates/navBar/MobileNavBarMenu";
import { SmallNabBarIcon } from "../../templates/navBar/SmallNavBarIcon";
/**
 * @typedef {import("../../templates/navBar/types").NavBarDropDownProps}  NavBarDropDownProps
 */
export const NavBar = () => {
  const {
    currentUserRealTime,
    logout: firebaseLogout,
    notifications,
    isConnoisseur,
    isCurious,
    isVendor,
  } = useAuth();

  const [navActive, setNavActive] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const wrapperRef = useRef(null);

  useClickOutsideDetector(wrapperRef, () => {
    setSelectedTab("");
    setNavActive(false);
  });

  const logout = useCallback(async () => {
    firebaseLogout()
      .then(() => {
        navigate("/");
        dispatch({ type: "hideLoading" });
      })
      .catch((error) => console.error("Sign Out Error", error));
  }, [dispatch, firebaseLogout, navigate]);

  const notificationCounter = useMemo(() => {
    return notifications.filter((notification) => !notification.seen)?.length;
  }, [notifications]);

  const handleNav = () => {
    setNavActive(!navActive);
  };
  const onSearch = () => {
    setShowSearch(true);
    setNavActive(false);
  };

  /**
   * @type {NavBarDropDownProps}
   */
  const userTab = useMemo(() => {
    return {
      title: `${currentUserRealTime?.username}` || "USER",
      subItems: [
        {
          title: "My Profile",
          path: `/profile/`,
        },
        {
          title: "My Connections",
          path: `/connections/`,
        },
        {
          title: "Help",
          path: `/help/`,
        },
        {
          title: "Notifications",
          path: "/notifications",
          extraClass: "lg:hidden",
          count: notificationCounter,
        },
        {
          title: "Log Out",
          path: "/login",
          extraClass: "!text-red-500",
          onClick: logout,
        },
      ],
    };
  }, [currentUserRealTime?.username, logout, notificationCounter]);

  /**
   * @type {Array<NavBarDropDownProps>}
   */
  const navItems = useMemo(() => {
    const items = [];
    if (isConnoisseur()) {
      items.push(sellerTab);
    }
    if (isConnoisseur() || isCurious()) {
      items.push(buyerTab);
    }
    if (isVendor()) {
      items.push(vendorTab);
    }
    if (currentUserRealTime?.role) {
      items.push(userTab);
    }
    if (items.length === 0) {
      items.push(NoAccountTab);
    }
    return items;
  }, [currentUserRealTime, isConnoisseur, isCurious, isVendor, userTab]);

  /**
   *
   * @type {(tab: string) => void}
   */
  const onSelectTab = (tab) => {
    if (tab === selectedTab) {
      setSelectedTab("");
      return;
    }
    setSelectedTab(tab);
  };

  return (
    <>
      <div
        ref={wrapperRef}
        className="z-20 bg-white flex justify-between items-center h-16  mx-auto w-full px-4 text-white sticky top-0 border-b border-aux-gray"
      >
        {/* Logo */}
        <a href="/dashboard">
          <img className="mr-auto" src="/logo.png" width="150" alt="Logo" />
        </a>
        {/* Desktop Navigation */}
        <DesktopNabBarMenu
          userIsLoggedIn={!!currentUserRealTime}
          onSearch={onSearch}
          onSelectTab={onSelectTab}
          selectedTab={selectedTab}
          notificationCounter={notificationCounter}
          notifications={notifications}
          menuItems={navItems}
        ></DesktopNabBarMenu>
        {/* Mobile Navigation Icon */}
        <SmallNabBarIcon isOpen={navActive} onClick={handleNav} />
        {/* Mobile Navigation Menu */}
        <MobileNabBarMenu
          userIsLoggedIn={!!currentUserRealTime}
          isOpen={navActive}
          onClick={handleNav}
          onSearch={onSearch}
          menuItems={navItems}
        />
      </div>
      {showSearch && <SearchModal show={showSearch} setShow={setShowSearch} />}
    </>
  );
};
