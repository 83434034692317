import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";

import { GiHamburgerMenu } from "react-icons/gi";
import { FaBell, FaSearch } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";

import { useAuth } from "../context/AuthContext";
import { Badge } from "react-bootstrap";
import Notification from "./Notificaton";
import moment from "moment";
import { useDispatch } from "react-redux";
import NavTab, { NavTabItem } from "./NavTab";
import SearchModal from "./SearchModal";
/**
 * @deprecated use NavBar instead
 */
function NavDarkReact() {
  const dispatch = useDispatch();

  const navRef = useRef(null);
  const offRef = useRef(null);
  const offRefBody = useRef(null);
  const offHeadRef = useRef(null);
  const [show, setShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const [expanded, setExpanded] = useState("false");

  const navToggle = () => {
    setShow(show ? false : true);
    setExpanded(expanded ? "false" : "true");
  };

  const closeNav = () => {
    setShow(false);
    setExpanded("false");
  };

  const [subscription, setSubscription] = useState(null);
  const [notSubscribed, setNotSubscribed] = useState(false);
  const [notificationCounter, setNotificationCounter] = useState(0);
  const [subSet, setSubSet] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const {
    currentUserRealTime,
    currentUser,
    logout: firebaseLogout,
    notifications,
    isConnoisseur,
    isCurious,
    mySub,
    isVendor,
  } = useAuth();

  const logout = async () => {
    firebaseLogout()
      .then(() => {
        setSubscription(null);
        navigate("/");
        dispatch({ type: "hideLoading" });
      })
      .catch((error) => console.error("Sign Out Error", error));
  };

  function useClickOutside(ref, onClickOutside) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          offRef.current &&
          offRefBody.current &&
          offHeadRef.current &&
          !ref.current.contains(event.target) &&
          !offRef.current.contains(event.target) &&
          !offRefBody.current.contains(event.target) &&
          !offHeadRef.current.contains(event.target)
        ) {
          onClickOutside();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("touchstart", handleClickOutside);
      };
    }, [ref, onClickOutside]);
  }

  useClickOutside(
    navRef,
    useCallback(() => {
      setShow(false);
      setExpanded("false");
    }, [])
  );

  const previewsMenuCon = [
    {
      title: "Incoming",
      path: `/shares/`,
    },
    {
      title: "Outgoing",
      path: `/profile-grid/`,
    },
    {
      title: "Upload",
      path: "/addpost",
    },
    /*{
      title: "Archived",
      path: `/archived-shares/`,
    },*/
  ];

  const previewsMenuCur = [
    {
      title: "Incoming",
      path: `/shares/`,
    },
    /*{
      title: "Archived",
      path: `/archived-shares/`,
    },*/
  ];

  const acquisitionsMenuCon = [
    /*{
      title: "Status Tracker Incoming",
      path: `/acquisition-status/`,
    },*/
    {
      title: "Incoming",
      path: `/acquisition-status-buyer/`,
    },
    {
      title: "Outgoing",
      path: `/acquisition-status-seller/`,
    },
    /*{
      title: "Archived",
      path: '/archived-acquisitions'
    }*/
    /*{
      title: "post-sale management",
      path: "/plans",
    },*/
  ];

  const acquisitionsMenuCur = [
    {
      title: "Incoming",
      path: `/acquisition-status-buyer/`,
    },
    /*{
      title: "Archived",
      path: '/archived-acquisitions'
    }*/
    /*{
      title: "post-sale services",
      path: "/plans",
    },*/
  ];

  const menuItemsCon = [
    {
      title: "PREVIEWS",
      subMenu: previewsMenuCon,
    },
    {
      title: "ACQUISITIONS",
      subMenu: acquisitionsMenuCon,
    },
    /*{
      title: "Connections",
      subMenu: connMenu,
    },
    {
      title: "User",
      subMenu: userMenuCon,
    },*/
  ];

  const menuItemsCur = [
    {
      title: "PREVIEWS",
      subMenu: previewsMenuCur,
    },
    {
      title: "ACQUISITIONS",
      subMenu: acquisitionsMenuCur,
    },
  ];

  const vendorTab = {
    title: "VENDOR",
    subMenu: [
      {
        title: "Pending Purchases",
        path: `/acquisition-status-buyer/`,
      },
    ],
  };

  const buyerTab = {
    title: "I Want to Buy",
    subItems: [
      {
        title: "Incoming Previews",
        path: `/shares/`,
      },
      {
        title: "Pending Purchases",
        path: `/acquisition-status-buyer/`,
      },
      {
        title: "My Feedback",
        path: `/my-feedback/`,
      },
      {
        title: "My Collection",
        path: `/collection-client/`,
      },
    ],
  };

  const sellerTab = {
    title: "I Want to Sell",
    subItems: [
      {
        title: "Outgoing Previews",
        path: `/profile-grid/`,
      },
      {
        title: "Pending Sales",
        path: `/acquisition-status-seller/`,
      },
      {
        title: "Upload New Preview",
        path: `/addPost/`,
      },
    ],
  };

  const userTab = useMemo(() => {
    return {
      title: `@${currentUserRealTime?.username}` || "USER",
      subMenu: [
        {
          title: "My Profile",
          path: `/profile/`,
        },
        {
          title: "My Connections",
          path: `/connections/`,
        },
        {
          title: "Help",
          path: `/help/`,
        },
      ],
    };
  }, [currentUserRealTime]);

  useEffect(() => {
    setNotificationCounter(
      notifications.filter((notification) => !notification.seen)?.length
    );
  }, [notifications]);

  useEffect(() => {
    if (currentUserRealTime) {
      setSubSet(!!currentUserRealTime?.role);
      setSubscription(currentUserRealTime?.role);
      setNotSubscribed(currentUserRealTime?.role === null);
    }
  }, [currentUserRealTime]);

  return (
    <>
      {currentUser ? (
        <>
          <Navbar
            ref={navRef}
            bg="light"
            expand="xl"
            fixed="top"
            style={{
              borderBottom: ".5px solid gray",
              padding: 0,
            }}
          >
            <Container fluid>
              <Navbar.Brand
                className="ps-1 ps-sm-2 ps-md-3 ps-lg-3 ps-xl-4"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/dashboard")}
              >
                <img src="/logo.png" width="150" alt="Logo" />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-xl`}
                onClick={navToggle}
              />
              <Navbar.Offcanvas
                keyboard={true}
                scroll={true}
                backdrop={true}
                id={`offcanvasNavbar-expand-xl`}
                aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                placement="end"
                show={show}
                expanded={expanded}
              >
                <Offcanvas.Header
                  ref={offHeadRef}
                  onClick={closeNav}
                  closeButton
                >
                  <Offcanvas.Title
                    id={`offcanvasNavbarLabel-expand-xl`}
                    ref={offRef}
                    className="d-flex flex-column"
                  >
                    <span className="mx-3">
                      {currentUserRealTime?.displayName}
                    </span>
                    {subscription && (
                      <span className="mt-2 ms-3 ps-1" style={{ fontSize: 16 }}>
                        + {mySub()}
                      </span>
                    )}
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body ref={offRefBody}>
                  <Nav className="justify-content-end flex-grow-1 pe-2">
                    {subSet && !notSubscribed ? (
                      <>
                        <NavDropdown
                          title={<FaSearch />}
                          id={`offcanvasNavbarDropdown-expand-xl`}
                          className="py-2 pe-5 ps-3"
                          align="end"
                          onClick={() => setShowSearch(true)}
                        />
                        {subSet && isConnoisseur() && (
                          <NavTab tab={sellerTab} closeNav={closeNav} />
                        )}
                        {subSet && (isConnoisseur() || isCurious()) && (
                          <NavTab tab={buyerTab} closeNav={closeNav} />
                        )}
                        {subSet && isVendor() && (
                          <NavTab tab={vendorTab} closeNav={closeNav} />
                        )}
                        <NavDropdown
                          title={userTab.title}
                          id={`offcanvasNavbarDropdown-expand-xl`}
                          className="py-2 pe-5 ps-3"
                          align="end"
                        >
                          {!show && (
                            <>
                              <div
                                className="mt-2 pb-2"
                                style={{ fontSize: 16 }}
                              >
                                <span
                                  style={{
                                    lineHeight: "3vh",
                                    fontWeight: "bold",
                                  }}
                                  className="mx-3"
                                >
                                  {currentUser.displayName}
                                </span>
                              </div>
                              {subscription && (
                                <div style={{ fontSize: 16 }}>
                                  <span className="ms-2 ps-2">+ {mySub()}</span>
                                </div>
                              )}
                              <NavDropdown.Divider />
                            </>
                          )}
                          {userTab.subMenu.map((sub) => (
                            <NavTabItem sub={sub} closeNav={closeNav} />
                          ))}
                          <NavDropdown.Item as="li">
                            <NavLink
                              onClick={logout}
                              className={`nav-link navbar-text rounded p-2 ${
                                "/login" === location.pathname &&
                                "text-secondary"
                              }`}
                              to="/login"
                            >
                              Log Out
                            </NavLink>
                          </NavDropdown.Item>
                        </NavDropdown>
                      </>
                    ) : (
                      <>
                        <NavDropdown
                          title="Account"
                          id={`offcanvasNavbarDropdown-expand-xl`}
                          className="py-2 pe-5 ps-3"
                          align="end"
                        >
                          <NavDropdown.Item as="li">
                            <NavLink
                              className={`nav-link navbar-text rounded p-2${
                                "/help" === location.pathname &&
                                "text-secondary"
                              }`}
                              to="/help"
                              onClick={closeNav}
                            >
                              Help
                            </NavLink>
                          </NavDropdown.Item>
                          <NavDropdown.Item as="li">
                            <NavLink
                              onClick={logout}
                              className={`nav-link navbar-text rounded p-2 ${
                                "/login" === location.pathname &&
                                "text-secondary"
                              }`}
                              to="/login"
                            >
                              Log Out
                            </NavLink>
                          </NavDropdown.Item>
                        </NavDropdown>
                      </>
                    )}
                    <NavDropdown
                      title={
                        show ? (
                          <div>
                            Notifications{" "}
                            {notificationCounter > 0 && (
                              <Badge pill bg="secondary">
                                {notificationCounter}
                              </Badge>
                            )}
                          </div>
                        ) : (
                          <div>
                            <FaBell />
                            {notificationCounter > 0 && (
                              <Badge pill bg="secondary">
                                {notificationCounter}
                              </Badge>
                            )}
                          </div>
                        )
                      }
                      id={`offcanvasNavbarDropdown-expand-xl`}
                      className="py-2 pe-5 ps-3"
                      align="end"
                    >
                      {notifications
                        .sort(
                          (a, b) => moment(b.createdAt) - moment(a.createdAt)
                        )
                        .slice(0, 3)
                        .map((notification) => (
                          <>
                            <NavDropdown.Item
                              as="li"
                              style={{ display: "flex" }}
                            >
                              <Notification notification={notification} />
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                          </>
                        ))}
                      {notifications.length === 0 && (
                        <>
                          <div
                            style={{
                              width: "270px",
                              height: "70px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            No notifications to show
                          </div>
                          <NavDropdown.Divider />
                        </>
                      )}
                      <NavDropdown.Item as="li">
                        <NavLink
                          to="/notifications"
                          onClick={closeNav}
                          className={`nav-link navbar-text rounded p-2 ms-1 ${
                            "/notifications" === location.pathname &&
                            "text-secondary"
                          }`}
                          style={{
                            color: "black",
                            fontSize: "14px",
                            textDecoration: "none",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          See All Notifications
                        </NavLink>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
          {showSearch && (
            <SearchModal show={showSearch} setShow={setShowSearch} />
          )}
        </>
      ) : (
        <Fragment>
          <Navbar
            bg="light"
            fixed="top"
            style={{ borderBottom: ".5px solid gray", padding: 0 }}
          >
            <Container fluid>
              <Navbar.Brand
                as="li"
                style={{
                  listStyle: "none",
                  fontSize: 25,
                }}
                className="ps-1 ps-sm-2 ps-md-3 ps-lg-3 ps-xl-4"
              >
                <NavLink
                  as={Link}
                  to="/"
                  className={`nav-link py-1  ${
                    "/" === location.pathname && "text-dark"
                  }`}
                >
                  <img
                    src="/logo.png"
                    width="120"
                    height="38"
                    className="d-inline-block align-top"
                    alt=""
                  />
                </NavLink>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbar-hamburger" align="end" />
              <Navbar.Collapse id="basic-nav-dropdown" align="end">
                <Nav className="ms-auto">
                  <NavDropdown
                    align="end"
                    id="basic-nav-dropdown"
                    title={<GiHamburgerMenu size="22" />}
                    menuVariant="light"
                  >
                    <NavDropdown.Item as="li">
                      <NavLink
                        as={Link}
                        className={`nav-link navbar-text rounded ${
                          "/login" === location.pathname && "text-dark"
                        }`}
                        to="/login"
                      >
                        Log In
                      </NavLink>
                    </NavDropdown.Item>
                    {/* <NavDropdown.Divider /> */}
                    <NavDropdown.Item as="li">
                      <NavLink
                        as={Link}
                        className={`nav-link navbar-text rounded ${
                          "/register" === location.pathname && "text-dark"
                        }`}
                        to="/register"
                      >
                        Join
                      </NavLink>
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Fragment>
      )}
    </>
  );
}

export default NavDarkReact;
