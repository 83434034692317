import { MDBBadge } from "mdb-react-ui-kit";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { HiOutlineArchiveBox } from "react-icons/hi2";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { toast } from "react-toastify";
import { useAuth } from "../context/AuthContext";
import { GrRevert } from "react-icons/gr";
import { FaTrash } from "react-icons/fa";
import Image from "react-bootstrap/Image";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function OrderGrid({ order, getOrders, setOrders }) {
  const { isCurious, isConnoisseur } = useAuth();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading } = useSelector((store) => store);
  const archiveRef = useRef(false);
  const revertRef = useRef(false);
  const deleteRef = useRef(false);

  const archiveAcquisition = async () => {
    dispatch({ type: "showLoading" });
    archiveRef.current = true;
    updateDoc(
      doc(db, "orders", order?.id),
      isCurious()
        ? {
            archivedByCurious: true,
          }
        : {
            archivedByConnoisseur: true,
          }
    ).then(() => {
      toast.success("Acquisition Status Archived");
      setOrders((prev) => prev.filter((p) => p.id !== order.id));
      dispatch({ type: "hideLoading" });
      archiveRef.current = false;
      getOrders();
    });
  };

  const revertAcqusition = () => {
    dispatch({ type: "showLoading" });
    revertRef.current = true;
    setDoc(
      doc(db, "orders", order?.id),
      isCurious()
        ? {
            ...order,
            archivedByCurious: false,
          }
        : {
            ...order,
            archivedByConnoisseur: false,
          }
    ).then(() => {
      toast.success("Acquisition Reverted");
      dispatch({ type: "hideLoading" });
      revertRef.current = false;
      getOrders();
    });
  };

  const deleteAcquisition = async () => {
    dispatch({ type: "showLoading" });
    deleteRef.current = true;
    deleteDoc(doc(db, "orders", order?.id)).then(() => {
      toast.success("Acquisition Status Deleted");
      dispatch({ type: "hideLoading" });
      deleteRef.current = false;
      getOrders();
    });
  };

  return (
    <div
      className="card border-muted text-center shadow-sm"
      style={{ width: "100%" }}
    >
      {order && (
        <>
          <div className="p-sm-1">
            <div className="pt-1">
              <h6
                className={`bg-white p-2 pt-3 pb-3 rounded border border-${order.statusColor} shadow-sm`}
              >
                <span
                  style={{
                    fontWeight: "300",
                    fontSize: 17,
                    fontStyle: "italic",
                  }}
                >
                  {order.status}
                </span>
              </h6>
              <h6 className="mt-2 italic">
                Requested by {order.userDisplayName}
              </h6>
              <h6
                className="bg-light p-2 rounded border-muted shadow-sm"
                style={{
                  whiteSpace: "nowrap",
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {order.postDescription}
              </h6>
            </div>
            <Image
              fluid
              src={order.imageURL}
              style={{
                height: "300px",
                objectFit: "cover",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(`/order-${isCurious() ? "s" : "o"}-status/${order.id}`)
              }
            />
            <h6 className="d-flex bg-light p-2 pb-2 pt-2 mt-2 rounded border-muted shadow-sm align-items-center justify-content-between">
              <span>
                Page{" "}
                <span className="font-weight-bold">{order.pageNumber}</span>
              </span>
              <div>
                {(isCurious() && order.archivedByCurious) ||
                (isConnoisseur() && order.archivedByConnoisseur) ? (
                  <BootstrapTooltip title="Undo archive">
                    <MDBBadge
                      pill
                      color="light"
                      className="p-2 m-2 bg-light text-dark border-dark shadow-sm bg-white"
                    >
                      {" "}
                      {loading && revertRef.current ? (
                        <Loader />
                      ) : (
                        <GrRevert
                          size={15}
                          onClick={revertAcqusition}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </MDBBadge>
                  </BootstrapTooltip>
                ) : (
                  <BootstrapTooltip title="Archive preview">
                    <span className="ms-4">
                      {" "}
                      <MDBBadge
                        pill
                        color="light"
                        className="p-2 mx-2 bg-light text-dark border-dark shadow-sm bg-white"
                      >
                        {loading && archiveRef.current ? (
                          <Loader />
                        ) : (
                          <HiOutlineArchiveBox
                            ref={archiveRef}
                            size={15}
                            onClick={archiveAcquisition}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </MDBBadge>
                    </span>
                  </BootstrapTooltip>
                )}
                {isConnoisseur() && (
                  <BootstrapTooltip title="Delete preview">
                    <MDBBadge
                      pill
                      color="light"
                      className="p-2 bg-light text-dark border-dark shadow-sm bg-white"
                    >
                      {" "}
                      {loading && deleteRef.current ? (
                        <Loader />
                      ) : (
                        <FaTrash
                          size={15}
                          onClick={deleteAcquisition}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </MDBBadge>
                  </BootstrapTooltip>
                )}
              </div>
            </h6>
          </div>
        </>
      )}
    </div>
  );
}

export default OrderGrid;
